let usedKeyWords = new Set();
let usedCallToActions = new Set();
let usedPosts = new Set();

export const generateContent = (caption, num) => {
  const keyWords = [caption.key_word_1, caption.key_word_2, caption.key_word_3];
  const callToActions = [
    caption.call_to_action_1,
    caption.call_to_action_2,
    caption.call_to_action_3,
  ];
  const posts = [caption.post_1, caption.post_2, caption.post_3];

  if (num === 1) {
    let availableKeyWords = keyWords.filter(
      (_, index) => !usedKeyWords.has(index)
    );
    if (availableKeyWords.length === 0) {
      usedKeyWords.clear();
      availableKeyWords = keyWords;
    }
    const selectedKeyWord = availableKeyWords[0];
    usedKeyWords.add(keyWords.indexOf(selectedKeyWord));
    return selectedKeyWord;
  } else if (num >= 2 && num <= 7) {
    let availableCallToActions = callToActions.filter(
      (_, index) => !usedCallToActions.has(index)
    );
    if (availableCallToActions.length === 0) {
      usedCallToActions.clear();
      availableCallToActions = callToActions;
    }
    const selectedCallToAction = availableCallToActions[0];
    usedCallToActions.add(callToActions.indexOf(selectedCallToAction));
    return selectedCallToAction;
  } else if (num > 7) {
    let availablePosts = posts.filter((_, index) => !usedPosts.has(index));
    if (availablePosts.length === 0) {
      usedPosts.clear();
      availablePosts = posts;
    }
    const selectedPost = availablePosts[0];
    usedPosts.add(posts.indexOf(selectedPost));
    return selectedPost;
  }
};

export const updateElementContent = (
  element,
  post,
  captions,
  currentTemplateId,
  customImages
) => {
  if (element.type === "image") {
    element.src = updateElementSrc(element, customImages, post);
  }
  if (
    element.type === "text" &&
    post?.edit_state?.pages[0]?.id !== currentTemplateId
  ) {
    element.text = captions[`${element.id}`];
  } else if (post?.edit_state?.pages[0]?.id === currentTemplateId) {
    element.text = post?.edit_state?.pages[0].children.find(
      (child) => child.id === element.id
    )?.text;
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const updateElementSrc = (element, customImages, post) => {
  const matchCustomImage = customImages.find((img) => img.id === element.id);

  const src = matchCustomImage
    ? matchCustomImage.url
    : element.id === "custom_image"
    ? post.image
    : element.src;

  return src;
};
