export const formatCredits = (credits) => {
  if (credits >= 1000000) {
    return `${(credits / 1000000).toFixed(0)} Mill`;
  } else if (credits >= 1000) {
    return `${(credits / 1000).toFixed(0)} K`;
  } else {
    return credits.toFixed(0);
  }
};

export const getTotalCredits = (orgData) => {
  if (!orgData) return 0;
  return (orgData.paid_credits || 0) + (orgData.free_credits || 0);
};


export const formatErrorMesage = (error) => {
  const errorMessage = error.replace(/_/g, " ");
  return errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
};
