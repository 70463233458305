import { useQuery } from "@tanstack/react-query";
import { PromptTemplateContext } from "./PromptTemplateContext";
import { fetchPromptTemplates } from "../../services";
import { useState } from "react";
import * as NotificationService from "../../utils/notificationService";
import { useParams } from "react-router-dom";


function PromptTempateProvider({ children }) {
  const { organizationskuuid } = useParams();
  const [orgSkuuid, setOrgSkuuid] = useState(organizationskuuid);

  const { data: promptTemplates, isLoading: isPromptTemplateLoading } = useQuery({
    queryKey: ["promptTemplates", orgSkuuid],
    queryFn: async () => await fetchPromptTemplates(orgSkuuid),
    enabled: !!orgSkuuid,
    onError: (error) => {
      NotificationService.notifyError(
        `Failed to fetch user prompt templates: ${error.message}`
      );
    },
  });

  const contextValue = {
    setOrgSkuuid,
    promptTemplates,
    isPromptTemplateLoading,
  };

  return (
    <PromptTemplateContext.Provider value={contextValue}>
      {children}
    </PromptTemplateContext.Provider>
  );
}

export default PromptTempateProvider;
