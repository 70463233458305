import { useQuery } from '@tanstack/react-query';

import { getOrganizationResourceFilesByCampaign } from '../../services';


export function useFetchFilesByCampaignId(organizationskuuid, campaignskuuid) {
  return useQuery({
    queryKey: ["campaign-files", campaignskuuid],
    queryFn: () =>
      getOrganizationResourceFilesByCampaign(
        organizationskuuid,
        campaignskuuid
      ),
  });
}
