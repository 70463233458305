import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams, useLocation} from "react-router-dom";

import { fetchApps } from "../services";
import Loading from "./common/Loading";
import * as NotificationService from "../utils/notificationService";
import SocialAccountsTable from "./settings/SocialAccountsTable";
import SelectLinkedinPageModal from "./settings/SelectLinkedinPageModal";
import SelectFacebookPageModal from "./settings/SelectFacebookPageModal";


function Settings() {
  let location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const { organizationskuuid } = useParams();
  const [isDeletingApp, setIsDeletingApp] = useState(false);
  const [needSelectPageId, setNeedSelectPageId] = useState(false);
  const [hasLinkedinPage, setHasLinkedinPage] = useState(false);
  const accessToken = queryParams.get("access_token");
  const accountName = queryParams.get("account_name");
  const socialAccount = queryParams.get("social_account");

  const getSocialNetworkSetter = (socialAccount) => {
    const socialNetworks = {
      FACEBOOK: setNeedSelectPageId,
      LINKEDIN: setHasLinkedinPage,
    };
  
    return socialNetworks[socialAccount];
  };

  useEffect(() => {
    if (!socialAccount || !accessToken) return;

    try {
      const setSocialNetwork = getSocialNetworkSetter(socialAccount);
      if (!setSocialNetwork){
        throw new Error(`Unsupported social network: ${socialAccount}`);
      }

      setSocialNetwork(true);
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(errorMessage);
    }
  }, [accessToken, socialAccount]); 
  
  const appsQuery = useQuery({
    queryKey: ["user-social-accounts", organizationskuuid],
    queryFn: async () => await fetchApps(organizationskuuid),
    staleTime: 10 * 1000,
  });

  return (
    <div className="flex flex-row p-5">
      {appsQuery.isLoading || isDeletingApp ? (
        <Loading />
      ) : (
        <div className="w-full">
          <SocialAccountsTable
            apps={appsQuery.data}
            organizationskuuid={organizationskuuid}
            isLoading={appsQuery.isLoading}
            setIsLoading={setIsDeletingApp}
            fetchApps={appsQuery.refetch}
          />
        </div>
      )}
      {needSelectPageId && (
        <SelectFacebookPageModal
          showModal={needSelectPageId}
          setshowModal={setNeedSelectPageId}
          facebookAccessToken={accessToken}
          facebookAccountName={accountName}
        />
      )}
      {hasLinkedinPage && (
        <SelectLinkedinPageModal
          organizationskuuid={organizationskuuid}
          showModal={hasLinkedinPage}
          setshowModal={setHasLinkedinPage}
          linkedinAccessToken={accessToken}
          linkedinAccountName={accountName}
        />
      )}
    </div>
  );
}

export default Settings;
