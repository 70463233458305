import React, { useEffect, useState } from "react";

import { updatePromptTemplate } from '../../services';
import { validateInput } from '../../utils/validations';
import * as NotificationService from "../../utils/notificationService";
import { READABLE_SITE_NAME } from '../../config/constants';
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";
import BaseModal from "../common/modals/BaseModal";


const EditTemplate = ({
  name,
  queryClient,
  organizationskuuid,
  description,
  templateSk,
  closeModal,
  reference,
  showModal,
}) => {
  const [promptName, setPromptName] = useState(name || "");
  const [promptDescription, setPromptDescription] = useState(description || "");

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({ name: "", description: "" });

  useEffect(() => {
    const isSameData = promptName === name && promptDescription === description;
    const isDataEmpty = !promptName.trim() || !promptDescription.trim();
    setIsSaveButtonDisabled(isDataEmpty || isSameData);
  }, [promptName, promptDescription]);

  const handleSave = async () => {
    try {
      const promptSkuuid = templateSk.split("#")[1];
      const updatedPromptTemplate = await updatePromptTemplate(
        organizationskuuid,
        promptSkuuid,
        promptName,
        promptDescription,
        reference
      );
      queryClient.setQueryData(
        ["promptTemplates", organizationskuuid],
        (oldData) => {
          return oldData.map((template) =>
            template.sk === templateSk
              ? { ...template, ...updatedPromptTemplate }
              : template
          );
        }
      );
      NotificationService.notifySuccess("Prompt template updated successfully");
      closeModal();
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to update prompt template: ${errorMessage}`
      );
    }
  };

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  const validatePromptDescription = () => {
    validateInput(
      promptDescription,
      "description",
      setErrors,
      "Prompt template description is required"
    );
  };


  const validateAllInputs = () => {
    validatePromptDescription();
  };

  return (
    <BaseModal
      handleClose={closeModal}
      headerMessage="Edit Prompt Template"
      saveLabel="Save"
      handleSave={handleSave}
      isSaveButtonDisabled={isSaveButtonDisabled}
      validateAllInputs={validateAllInputs}
      isDismissible={true}
      showModal={showModal}
    >
      <div className="space-y-6">
        <div>
          <label className="block text-black">Prompt Name *</label>
          <textarea
            autoFocus
            className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
            placeholder="Give your prompt a name that you can easily recognize"
            value={promptName}
            onChange={handleInputChange(
              setPromptName,
              "name",
              "Prompt template name is required"
            )}
          />
          <InputErrorDisplayer message={errors.name} />
        </div>
        <div>
          <label className="block text-black">Prompt Description *</label>
          <textarea
            className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
            rows="6"
            value={promptDescription}
            onBlur={validatePromptDescription}
            onChange={handleInputChange(
              setPromptDescription,
              "description",
              "Prompt template description is required"
            )}
            placeholder={`Write your prompt details as the question you would like to ask ${READABLE_SITE_NAME}’s AI in order to get a fine-tuned response.`}
          />
          <InputErrorDisplayer message={errors.description} />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditTemplate;
