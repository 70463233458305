export const Tooltip = ({ message }) => {
	return (
		<div
			className="absolute bg-gray-800 text-white text-sm px-3 py-1 rounded shadow-lg transition-opacity duration-200 z-50"
			style={{
				bottom: "100%",
				left: "50%",
				transform: "translateX(-50%)",
				marginBottom: "8px",
			}}
		>
			{message}
		</div>
	);
};
