import { FaSpinner } from "react-icons/fa";

import { stateMap } from "../../data/CampaignStates";
import { truncateContent } from "../../utils";
import StatusType from "../../data/enums/statusType";
import { FILE_ICONS } from "../../config/constants";
import TrashButton from "../common/buttons/TrashButton";

function FileList({
  files = [],
  isLoadingVisible = true,
  showDeleteOption = false,
  handleRemoveFile = () => {},
  styles = ""
}) {
  const statusKeys = Object.keys(stateMap);

  const getIconForFile = (fileName) => {
    const fileExtension = Object.keys(FILE_ICONS).find((fileExt) =>
      fileName.endsWith(fileExt)
    );

    return FILE_ICONS[fileExtension] || null;
  };

  return (
    <div className="flex flex-col gap-3">
      {files.map((file, idx) => {
        const status = statusKeys.includes(file.status)
          ? stateMap[file.status].title
          : "Uploading...";

        return (
          <div key={`list-file-${idx}`} className={`flex items-center gap-1 ${styles}`}>
            {getIconForFile(file.file_name)}
            <span className="ml-2 text-gray-700 text-sm">
              {truncateContent(file.file_name, 40).truncatedText}
            </span>

            <div className="ml-auto flex gap-2">
              {(status === StatusType.toReadable(StatusType.UPLOADING) &&
                isLoadingVisible) && (
                <>
                  <FaSpinner className="animate-spin" />
                  <span className="text-gray-600 text-xs">{status}</span>
                </>
              )}
              {showDeleteOption && (
                <TrashButton
                  label={`Delete ${file.file_name}`}
                  onClick={() => handleRemoveFile(file)}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default FileList;
