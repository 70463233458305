import React, { useRef} from "react";
import { FaBookOpen, FaCloudUploadAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import {
  useCreateFile,
  useFetchFilesByCampaignId,
  useUploadFile,
  useGetPresignedUrl,
} from "../../hooks/File";
import Dropdown from "../common/forms/Dropdown";
import * as NotificationService from "../../utils/notificationService";
import FileList from "../FileList/FileList";
import DragDropFile from "../DragDropFile/DragDropFile";
import FileInput from "../common/input/FileInput";
import { validateFileSizeNType } from "../../utils";
import { MAX_SIZE_PER_FILE, MAX_FILES } from "../../config/constants";

const TOTAL_STORAGE_LIMIT = 150 * 1024 ** 2;

function CampaignKnowledge({
  postuuid,
  knowledgeBases,
  knowledgeOption,
  setKnowledgeOption,
}) {
  const { organizationskuuid, campaignskuuid } = useParams();
  const queryClient = useQueryClient();
  const { data: files } = useFetchFilesByCampaignId(
    organizationskuuid,
    campaignskuuid
  );
  const createFile = useCreateFile(organizationskuuid);
  const createPresignedUrl = useGetPresignedUrl();
  const fileInputRef = useRef(null);
  const uploadFile = useUploadFile();
  const filesLength = files.length;

  const sumTotalFileSize = (files) => {
    return files.reduce((total, file) => total + (file?.file_size || 0), 0);
  };

  const calculateKnowledgeCapacity = () => {
    const totalFilesSize = sumTotalFileSize(files);

    const totalKnowledgeBaseFileSize = knowledgeOption?.files
      ? sumTotalFileSize(knowledgeOption.files)
      : 0;

    const total = totalFilesSize + totalKnowledgeBaseFileSize;
    return Math.max(Math.round((total * 100) / TOTAL_STORAGE_LIMIT), 1);
  };

  const handleValidFiles = (newFiles) => {
    const validFiles = validateFileSizeNType(newFiles, MAX_SIZE_PER_FILE);

    const totalFiles =
      filesLength + (knowledgeOption?.files.length || 0) + validFiles.length;
    if (totalFiles > MAX_FILES) {
      throw new Error(`You exceed the file maximum limit: ${MAX_FILES}`);
    }

    return validFiles;
  };

  const processFileUpload = (validFiles) => {
    const filePayload = validFiles.map(async (file) => {
      const { presigned_url, file_s3_uri, file_id } =
        await createPresignedUrl.mutateAsync({
          file_name: file.name,
          organization_skuuid: organizationskuuid,
        });

      await uploadFile.mutateAsync({ file, presigned_url });

      const fileData = createFile.mutateAsync({
        id: file_id,
        postId: postuuid,
        content: file_s3_uri,
        file_name: file.name,
        file_size: file.size,
        status: "UPLOADING",
        origin_sk: `CP#${campaignskuuid}`,
      });

      return fileData;
    });

    return filePayload;
  };

  const uploadFiles = async (files) => {
    try {
      const fileList = Object.values(files);
      if (fileList.length === 0) return;

      const validFiles = handleValidFiles(fileList);

      const filePayload = processFileUpload(validFiles);
      await Promise.all(filePayload);

      const statusMessage = filePayload.length > 1 ? "files have" : "file has";
      NotificationService.notifySuccess(
        `Your ${statusMessage} been uploaded successfully`
      );
      queryClient.invalidateQueries(["campaign-files", campaignskuuid]);
    } catch (error) {
      const errorMessage = error.message || "Failed to the file.";
      NotificationService.notifyError(errorMessage);
    }
  };

  const handleFileDrop = async (e) => {
    await uploadFiles(e.dataTransfer.files);
  };

  const handleFileChange = async (e) => {
    await uploadFiles(e.target.files);
  }

  const porcentange = filesLength === 0 ? "0" : calculateKnowledgeCapacity();

  return (
    <div className="flex flex-col gap-5 border border-gray-300 rounded-xl p-5">
      <div className="flex gap-3">
        <FaBookOpen className="text-gray-500 w-5 h-5" />
        <h1 className="text-base">Knowledge</h1>
      </div>

      <Dropdown
        selectedOption={knowledgeOption}
        options={knowledgeBases}
        onSelect={setKnowledgeOption}
        labelExtractor={(option) => option.title}
        title="Select knowledge base"
        defaultTitle="Select knowledge base"
      />

      <div className="flex flex-col gap-2">
        <div className="h-2 border rounded-xl">
          <div
            className="h-2 bg-main-blue rounded-xl"
            style={{ width: `${porcentange}%` }}
          ></div>
        </div>
        <span className="text-sm text-gray-500">
          {`${porcentange}% of knowledge capacity used`}
        </span>
      </div>

      {filesLength === 0 ? (
        <DragDropFile
          onDrop={handleFileDrop}
          onChange={handleFileChange}
        />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <FaCloudUploadAlt className="text-gray-500 w-5 h-5" />
              <h1 className="text-base">Uploads</h1>
            </div>
            <FileInput
              onChange={handleFileChange}
              fileInputRef={fileInputRef}
              onClick={() => fileInputRef.current && fileInputRef.current.click()}
              isMultiple={true}
            />
          </div>

          <FileList files={files} />
        </>
      )}
    </div>
  );
}

export default CampaignKnowledge;
