import { validFileTypes } from "../config/constants";


export const validateOrgNameInput = (input, field, setErrors, errorMessage) => {
  const inputTrim = input.trim();
  const isInputValidValue = isInputValid(input);
  const noSpaces = inputTrim.replace(/\s/g, "");

  let resultErrorMessage = errorMessage;

  if (!isInputValidValue) resultErrorMessage = `Invalid ${field}. Please avoid special characters.`;
  if (noSpaces.length > 0 && noSpaces.length < 3) resultErrorMessage = "Name must be at least 3 characters long.";
  if (!inputTrim) resultErrorMessage = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;

  setErrors((prevErrors) => ({
    ...prevErrors,
    [field]: resultErrorMessage,
  }));
};

export const validateInput = (input, field, setErrors, errorMessage) => {
  if (!input.trim()) {
    const resultErrorMessage =
      errorMessage ||
      `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: resultErrorMessage,
    }));
    return resultErrorMessage;
  } else {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    return null;
  }
};

export const validateEmailInput = (
  input,
  field,
  setErrors,
  existingEmails = [],
) => {
  const isEmail = isValidEmail(input);

  if (!isEmail) {
    const resultErrorMessage = `Email is invalid.`;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: resultErrorMessage,
    }));
    return resultErrorMessage;
  }

  const emailExists = existingEmails.some(
    (email) => email.toLowerCase() === input.toLowerCase()
  );

  if (emailExists) {
    const duplicateErrorMessage = "Email already exists.";
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: duplicateErrorMessage,
    }));
    return duplicateErrorMessage;
  }

  setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  return null;
};

export function isEmpty(obj) {
  if (obj === null || obj === undefined) {
    return true;
  }
  return Object.keys(obj).length === 0;
}

export function isValidMetric(value) {
  if (typeof value === "string" && isNaN(Number(value))) {
    throw new Error("Metrics cannot be letters");
  }

  if (!Number.isInteger(Number(value))) {
    throw new Error("Metrics cannot be a decimal number");
  }

  if (Number(value) < 0) {
    throw new Error("Metrics cannot be negative");
  }

  return true;
}

export function isValidFormat(input) {
  const regex =
    /^\[\(([\w#-]+ \d{2}\/\d{2}\/\d{4} \d{2}:\d{2})\)(, \(([\w#-]+ \d{2}\/\d{2}\/\d{4} \d{2}:\d{2})\))*\]$/;

  return regex.test(input);
}

export function isValidFutureDate(date) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const inputDate = new Date(date);

  return inputDate >= today;
}

export const isInputValid = (name) => {
  const alphanumericWithAccentsRegex = /^[\p{L}\p{N} ]{3,}$/u;
  const inputTrim = name.trim();

  return (
    alphanumericWithAccentsRegex.test(name) && 
    inputTrim &&
    inputTrim.length >= 3
  );
};

export function isValidEmail(email) {
  if (typeof email !== "string") {
    return false;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const validateFileSizeNType = (files, maxFileSizeMB) => {
  const validFiles = files.filter((file) => {
    const isValidType = validFileTypes.includes(file.type);

    const isValidSize = file.size <= maxFileSizeMB;
    return isValidType && isValidSize;
  });

  return validFiles;
};
