export const formatFileData = (files) => {
  const formatFiles = files.map((file) => {
    return {
      content: "",
      file_name: file.name,
      file_size: file.size,
      pk: "",
      sk: "",
      status: "",
    };
  });

  return formatFiles;
};
