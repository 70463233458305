import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";

export const getOrganizationResourceImages = async (
  organizationId,
  resourceType
) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${organizationId}/resource/${resourceType}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );

    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to getMyOrganizations:", error);
    throw error;
  }
};

export const postOrganizationResourceImages = async (
  organizationId,
  campaignId,
  postId,
  image
) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${organizationId}/resource`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({
          campaign_id: campaignId,
          post_id: postId,
          content: image,
        }),
      }
    );

    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to getMyOrganizations:", error);
    throw error;
  }
};

export const deleteOrganizationResourceImages = async (
  organizationId,
  resourceId
) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${organizationId}/resource/img/${resourceId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );

    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to getMyOrganizations:", error);
    throw error;
  }
};

export const postOrganizationResourceFile = async (organizationId, payload) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${organizationId}/file`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to create OrganizationResource:", error);
    throw error;
  }
};

export const getOrganizationResourceFilesByCampaign = async (
  organizationId,
  campaignId
) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${organizationId}/campaign/${campaignId}/file`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );

    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to get OrganizationResources by campaign:", error);
    throw error;
  }
};

export const getORganizationResourceFileById = async (
  organizationId,
  fileId
) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${organizationId}/file/${fileId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );

    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to get OrganizationResource: ", error);
    throw error;
  }
};

export const deleteOrganizationResourceFile = async (
  organizationId,
  skUuid,
  fileId
) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${organizationId}/origin/${skUuid}/file/${fileId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );

    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to delete OrganizationResource: ", error);
    throw error;
  }
};
