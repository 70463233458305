import React from 'react'
import { NavLink } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { ReactComponent as CampaignIcon } from "../../assets/campaign.svg";
import { fetchProjectCampaigns } from '../../services';
import { extractPkSk, truncateContent } from '../../utils';


function CampaignList({ organizationskuuid, projectskuuid, styles }) {
  const campaignsQuery = useQuery({
    queryKey: [`project-${projectskuuid}-campaigns`],
    queryFn: () => fetchProjectCampaigns(projectskuuid, organizationskuuid),
    staleTime: 10 * 1000,
  });

  return (
    <ul className="mt-2 list-none text-gray-700 flex pl-6 flex-col overflow-y-auto no-scrollbar">
      {campaignsQuery.data.map((campaign) => {
        const { skuuid: campaignuuid } = extractPkSk(campaign.id);
        return (
          <li key={`campaign-link-${campaignuuid}`}>
            <NavLink
              to={`organizations/${organizationskuuid}/projects/${projectskuuid}/campaigns/${campaignuuid}/assistant`}
              className={(navData) =>
                `self-stretch h-12 px-4 justify-start items-center gap-6 inline-flex w-full ${styles(navData)}`
              }
            >
              <CampaignIcon width={28} height={28} />
              <div className="grow shrink basis-0 self-stretch py-3 flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="self-stretch text-sm font-normal leading-normal tracking-wide">
                  {truncateContent(campaign.title, 10).truncatedText}
                </div>
              </div>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}

export default CampaignList;
