import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";

import Dropdown from "../common/forms/Dropdown";
import RoleType from "../../data/enums/roleType";
import { validateInput, validateEmailInput } from "../../utils/validations";
import CustomInput from "../common/forms/CustomInput";

export default function CollaboratorForm({
  currentCollaboratorEmails,
  collaborator,
  handleCollaboratorChange,
  handleRemoveCollaborator,
  canAddCollaborator,
  addCollaborator,
}) {
  const initialErrors = { name: "", email: "", role: "" };

  const [errors, setErrors] = useState(initialErrors);
  const handleInputChange = (field, errorMessage) => (e) => {
    const value = e.target.value;
    validateInput(value, field, setErrors, errorMessage);
    if (field === "email") {
      validateEmailInput(value, field, setErrors, currentCollaboratorEmails);
    }
    handleCollaboratorChange(field, value);
  };

  return (
    <>
      <div className="flex items-center space-x-4 items-baseline">
        <div className="relative w-1/3">
          <CustomInput
            autoFocus={true}
            id={`collaborator_name`}
            type="text"
            label="Name *"
            value={collaborator.name}
            error={errors.name}
            onChange={handleInputChange("name", "Name is required.")}
          />
        </div>
        <div className="relative w-1/3">
          <CustomInput
            autoFocus={false}
            id={`collaborator_email`}
            type="email"
            label="email *"
            value={collaborator.email}
            error={errors.email}
            onChange={handleInputChange("email", "email is required.")}
          />
        </div>
        <div className="relative w-1/3">
          <Dropdown
            selectedOption={collaborator.role}
            options={RoleType.getRoleOptions()}
            onSelect={(option) => handleCollaboratorChange("role", option)}
            labelExtractor={(option) => RoleType.toReadable(option)}
            title="Role"
            defaultTitle="Role *"
          />
        </div>
        <button
          type="button"
          className="text-red-500 hover:text-red-700"
          onClick={handleRemoveCollaborator}
        >
          <FaTrash />
        </button>

        <button
          type="button"
          className={`text-blue-500 hover:text-blue-700
            ${!canAddCollaborator && "opacity-50 cursor-not-allowed"}`}
          onClick={addCollaborator}
          disabled={!canAddCollaborator}
          aria-label="Add Collaborator"
        >
          <span className="text-2xl font-bold">+</span>
        </button>
      </div>
    </>
  );
}
