import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";
import { extractPkSk } from "../utils/common";

export const getMyOrganizations = async (userName) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/user/${userName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );

    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to getMyOrganizations:", error);
    throw error;
  }
};

export const updateOrganization = async (OrganizationId, payload) => {
  const { pkuuid, skuuid } = extractPkSk(OrganizationId);
  try {
    const session = await fetchAuthSession();

    const response = await fetch(
      `${API_SERVER}/organization/${skuuid}/organization_owner/${pkuuid}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(responseData.error || "Network response was not ok");
    }
    return responseData;
  } catch (error) {
    console.error("Failed to getMyOrganizations:", error);
    throw error;
  }
};

export const getOrganizationById = async (orgId) => {
  try {
    const { pkuuid: orgOwnerEmail, skuuid: orgSkuuid } = extractPkSk(orgId);

    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${orgSkuuid}/organization_owner/${orgOwnerEmail}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );

    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to getMyOrganizations:", error);
    throw error;
  }
};

export const createOrganization = async (payload) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/organization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed in createOrganization:", error);
    throw error;
  }
};
