import React from "react";
import { FaTrash } from "react-icons/fa";

export default function TrashButton({ label, onClick }) {
  return (
    <button
      type="button"
      className="text-red-500 hover:text-red-700"
      onClick={onClick}
      aria-label={label}
    >
      <FaTrash />
    </button>
  );
}
