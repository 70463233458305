import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { validateInput } from "../../utils/validations";
import { extractPkSk } from "../../utils/common";
import * as NotificationService from "../../utils/notificationService";
import AIModelType from "../../data/enums/AI_model_type";
import StatusType from "../../data/enums/statusType";
import KbStatusType from "../../data/enums/Kb_statusType";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";
import Dropdown from "../common/forms/Dropdown";
import { createPost } from "../../services/index";
import { useParams } from 'react-router-dom';
import BaseModal from '../common/modals/BaseModal';



export default function CreatePostModal({
  openModal,
  setOpenModal,
  setSelectedPostSkuuid,
  knowledgeBases,
  campaignId
}) {
  const queryClient = useQueryClient();
  const initalPost = {
    title: "",
    ai_model: AIModelType.OPEN_AI,
    knowledge_base_id: null,
    knowledge_base: null,
    campaign_id: campaignId,
    status: StatusType.READY,
  };

  const [newPost, setNewPost] = useState(initalPost);
  const initialErrors = { title: "", ai_model: "" };
  const [errors, setErrors] = useState(initialErrors);
  const [inputValid, setInputValid] = useState({
    title: true,
  });
  const { organizationskuuid } = useParams();
  const handleInputChange = (field, errorMessage) => (e) => {
    const value = e.target.value;
    setNewPost((prevState) => ({ ...prevState, [field]: value, campaign_id: campaignId }));
    validateInput(value, field, setErrors, errorMessage);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setNewPost(initalPost);
    setErrors(initialErrors);
  };

  const handleSelectAIModel = (option) => {
    setNewPost({ ...newPost, "ai_model": option })
  }

  const handleSelectKb = (option) => {
    setNewPost({
      ...newPost,
      "knowledge_base": option,
      "knowledge_base_id": option.id,
    })
  }

  const validateTitle = () => {
    validateInput(
      newPost.title,
      "title",
      setErrors,
      "Post title is required."
    );
  }

  const validateAllInputs = () => {
    validateTitle();
  };

  const createPostHandler = async () => {
    try {
      const isTitleValid = newPost.title.trim() !== "";

      setInputValid({
        title: isTitleValid,
      });

      if (!isTitleValid) {
        return;
      }

      const createPostPayload = {
        ...newPost,
        "knowledge_base_id": newPost.knowledge_base_id ?? "default",
      }

      const postCreated = await createPost(createPostPayload, organizationskuuid);
      NotificationService.notifySuccess("Post created successfully");
      const { skuuid: campaignskuuid } = extractPkSk(campaignId);
      queryClient.setQueryData([`campaign-${campaignskuuid}-posts`], (oldPosts) => {
        return oldPosts ? [...oldPosts, postCreated] : [postCreated];
      });
      const { skuuid: newPostSkuuid } = extractPkSk(postCreated.id);
      setSelectedPostSkuuid(newPostSkuuid);
      handleCancel();
      setOpenModal(false);
    } catch (error) {
      console.error("Failed to create Post: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to create Post: ${errorMessage}`
      );
    }
  };

  const readyKbs = (knowledgeBases ?? []).filter((kb) => kb.status === KbStatusType.READY);
  return (
    <BaseModal
      headerMessage="Create Post"
      showModal={openModal}
      isDismissible={true}
      size="lg"
      handleClose={handleCancel}
      mainClassName="rounded-2xl"
      saveLabel="Create"
      saveDisabled={
        newPost.title.trim().length === 0
      }
      handleSave={createPostHandler}
      handleSaveDisabled={validateAllInputs}
      bodyClassName="p-3 py-6"
    >
      <div className="space-y-6">
        <div className="relative">
          <input
            autoFocus
            className={`relative ${!inputValid.title
              ? "block px-2.5 pb-2.5 pt-4 w-full h-[56px] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
              : "block px-2.5 pb-2.5 pt-4 w-full h-[56px] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
              }`}
            type="text"
            id="post_title"
            value={newPost.title}
            onChange={handleInputChange(
              "title",
              "Post title is required."
            )}
            placeholder=""
          />
          <label
            htmlFor="post_title"
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Title *
          </label>
        </div>
        <InputErrorDisplayer message={errors.title} />

        <Dropdown
          selectedOption={newPost.ai_model}
          options={AIModelType.getAiModelOptions()}
          onSelect={handleSelectAIModel}
          labelExtractor={(option) => AIModelType.toReadable(option)}
          title="AI Model"
          defaultTitle="AI Model"
        />
        <InputErrorDisplayer message={errors.ai_model} />

        <Dropdown
          selectedOption={newPost.knowledge_base}
          options={readyKbs}
          onSelect={handleSelectKb}
          labelExtractor={(option) => option.title}
          title="Knowledge Base"
          defaultTitle="Select Knowledge Base"
        />
      </div>
    </BaseModal>
  );
}
