import { fetchAuthSession } from "aws-amplify/auth";
import { callbackURL } from "./common";
import {
  APPS_LINKEDIN_OAUTH_URL,
  APPS_FACEBOOK_OAUTH_URL,
  APPS_LINKEDIN_CLIENT_ID,
  APPS_X_OAUTH_URL,
  APPS_X_CLIENT_ID,
  APPS_FACEBOOK_CLIENT_ID,
  API_SERVER,
  APPS_INSTAGRAM_OAUTH_URL,
  APPS_INSTAGRAM_CLIENT_ID,
} from "../config/constants";
import { ReactComponent as LinkedInIcon } from "../assets/linkedin_icon.svg";
import { ReactComponent as XIcon } from "../assets/x.svg";
import { ReactComponent as FacebookIcon } from "../assets/facebook_icon.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagram_icon.svg";

import * as NotificationService from "./notificationService";
import SocialNetworkType from "../data/enums/socialNetworkType";


export const socialPlatformIconsMap = {
  [SocialNetworkType.LINKEDIN]: LinkedInIcon,
  [SocialNetworkType.X]: XIcon,
  [SocialNetworkType.FACEBOOK]: FacebookIcon,
  [SocialNetworkType.INSTAGRAM]: InstagramIcon,
};

export const getSocialPlatformName = (name) => {
  let appName = name;
  if (name?.includes("#")) appName = name.split("#")[1];
  return appName;
};

export const getSocialPlatformIcon = (name) => {
  const Icon = socialPlatformIconsMap[getSocialPlatformName(name)];
  return <Icon className="mr-3" />;
};

export const socialPlatformConnectCallbacksMap = {
  LinkedIn: (accountName) => {
    const metadata = JSON.stringify({ account_name: accountName });
    const encodedMetadata = btoa(metadata);
    const clientId = APPS_LINKEDIN_CLIENT_ID;
    const callbackUrl = callbackURL("LinkedIn");
    const scopes = "w_member_social%20openid%20profile%20r_basicprofile%20rw_organization_admin%20w_organization_social";

    const location = `${APPS_LINKEDIN_OAUTH_URL}`+
      `?client_id=${clientId}` +
      `&redirect_uri=${callbackUrl}` +
      `&response_type=code` +
      `&scope=${scopes}` +
      `&state=${encodedMetadata}`
    ;
    window.location = location;
  },
  X: (accountName) => {
    const metadata = JSON.stringify({ account_name: accountName });
    const encodedMetadata = btoa(metadata);
    const clientId = APPS_X_CLIENT_ID;
    const callbackUrl = callbackURL("X");
    const scopes = "tweet.read%20tweet.write%20users.read%20offline.access";

    const location = `${APPS_X_OAUTH_URL}`+
      `?client_id=${clientId}` +
      `&redirect_uri=${callbackUrl}` +
      `&response_type=code` +
      `&scope=${scopes}` +
      `&state=${encodedMetadata}` +
      `&code_challenge=challenge` +
      `&code_challenge_method=plain`
    ;
    window.location = location;
  },
  FACEBOOK: (accountName) => {
    const metadata = JSON.stringify({ account_name: accountName });
    const encodedMetadata = btoa(metadata);
    const clientId = APPS_FACEBOOK_CLIENT_ID;
    const callbackUrl = callbackURL("Facebook");
    const permissions = [
      "email",
      "public_profile",
      "pages_show_list",
      "pages_read_engagement",
      "pages_manage_posts",
      "pages_read_user_content",
      "read_insights",
    ];
    const scopes = permissions.join(",");

    const location = `${APPS_FACEBOOK_OAUTH_URL}`+
      `?client_id=${clientId}` +
      `&redirect_uri=${callbackUrl}` +
      `&response_type=code` +
      `&scope=${scopes}` +
      `&state=${encodedMetadata}`
    ;
    window.location = location;
  },
  INSTAGRAM: (accountName) => {
    const metadata = JSON.stringify({ account_name: accountName });
    const encodedMetadata = btoa(metadata);
    const clientId = APPS_INSTAGRAM_CLIENT_ID;
    const callbackUrl = callbackURL("Instagram");
    const scopes = "instagram_business_basic,instagram_business_content_publish,instagram_business_manage_insights";

    const location = `${APPS_INSTAGRAM_OAUTH_URL}`+
      `?client_id=${clientId}` +
      `&redirect_uri=${callbackUrl}` +
      `&response_type=code` +
      `&scope=${scopes}` +
      `&state=${encodedMetadata}`
    ;
    window.location = location;
  },
};

export const socialPlatformDisconnectCallbacksMap = {
  LinkedIn: (orgSkuuid, appId, setIsLoading, fetchApps) => {
    fetchAuthSession().then((session) => {
      setIsLoading(true);
      let subAppId = appId;
      if (appId.includes("#") && appId.split("#").length >= 3) {
        subAppId = appId.split("#")[2];
      }
      fetch(API_SERVER + `/organization/${orgSkuuid}/social-accounts/linkedin/disconnect/${subAppId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.message || "Network response was not ok");
          }
          NotificationService.notifySuccess(
            data.status || "Social network disconnected successfully"
          );
          fetchApps();
        })
        .catch((error) => {
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(
            `Failed to disconnect: ${errorMessage}`
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  },
  X: (orgSkuuid, appId, setIsLoading, fetchApps) => {
    fetchAuthSession().then((session) => {
      setIsLoading(true);
      let subAppId = appId;
      if (appId.includes("#") && appId.split("#").length >= 3) {
        subAppId = appId.split("#")[2];
      }
      fetch(API_SERVER + `/organization/${orgSkuuid}/social-accounts/x/disconnect/${subAppId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.message || "Network response was not ok");
          }
          NotificationService.notifySuccess(
            data.status || "Social network disconnected successfully"
          );
          fetchApps();
        })
        .catch((error) => {
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(
            `Failed to disconnect: ${errorMessage}`
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  },
  FACEBOOK: (orgSkuuid, appId, setIsLoading, fetchApps) => {
    fetchAuthSession().then((session) => {
      setIsLoading(true);
      let subAppId = appId;
      const appIdSplit = appId.split("#");
      if (appIdSplit.length >= 3) {
        subAppId =appIdSplit[2];
      }
      fetch(API_SERVER + `/organization/${orgSkuuid}/social-accounts/facebook/disconnect/${subAppId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.message || "Network response was not ok");
          }
          NotificationService.notifySuccess(
            data.status || "Social network disconnected successfully"
          );
          fetchApps();
        })
        .catch((error) => {
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(
            `Failed to disconnect: ${errorMessage}`
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  },
  INSTAGRAM: (orgSkuuid, appId, setIsLoading, fetchApps) => {
    fetchAuthSession().then((session) => {
      setIsLoading(true);
      let subAppId = appId;
      if (appId.includes("#") && appId.split("#").length >= 3) {
        subAppId = appId.split("#")[2];
      }
      fetch(API_SERVER + `/organization/${orgSkuuid}/social-accounts/instagram/disconnect/${subAppId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.message || "Network response was not ok");
          }
          NotificationService.notifySuccess(
            data.status || "Social network disconnected successfully"
          );
          fetchApps();
        })
        .catch((error) => {
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(
            `Failed to disconnect: ${errorMessage}`
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  },
};
