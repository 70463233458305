import React, { useEffect } from 'react';
import SendIcon from "../../assets/sendIcon2.svg";
import SendIconDisabled from "../../assets/sendIconDisabled.svg";
import InputErrorDisplayer from '../common/forms/InputErrorDisplayer';
import SkipQuestionsSelector from './SkipQuestionsSelector';

const AssistantMessageInput = ({
    messageInput, 
    handleChange, 
    handleKeyDown, 
    disabled, 
    handleSendClick, 
    skipQuestions, 
    setSkipQuestions,
    errorMessage,
}) => {

  useEffect(() => {
    return () => {
      setSkipQuestions(false);
    }
  }, []);

  return (
    <div className="relative pt-4">
      <div className="relative">
        <textarea
          value={messageInput}
          placeholder="Ask or write anything"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          rows="2"
          className="block w-full pr-32 pl-4 py-3 border rounded-xl sm:text-md focus:outline-none resize-y custom-textarea"
        ></textarea>
        <img
          onClick={disabled || !messageInput ? null : handleSendClick}
          src={disabled || !messageInput ? SendIconDisabled : SendIcon}
          alt="Send Icon"
          className={`absolute right-[80px] top-3 w-10 h-10 ${disabled || !messageInput ? "cursor-not-allowed": "cursor-pointer"} mt-2`}
        />
        <SkipQuestionsSelector
          skipQuestions={skipQuestions}
          setSkipQuestions={setSkipQuestions}
          disabled={disabled || !messageInput}
        />
      </div>
      <InputErrorDisplayer message={errorMessage} />
    </div>
  );
};

export default AssistantMessageInput;