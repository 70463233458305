import { FaFacebook, FaInstagram, FaLinkedin, FaXTwitter } from "react-icons/fa6";
import SocialNetworkType from "../data/enums/socialNetworkType";

const socialNetworksDataMap = [
  { icon: FaXTwitter, bgColor: "bg-black", type: SocialNetworkType.X },
  { icon: FaLinkedin, bgColor: "bg-[#0a66c2]", type: SocialNetworkType.LINKEDIN },
  { icon: FaInstagram, bgColor: "bg-gradient-to-r from-[#F58529] via-[#DD2A7B] to-[#8134AF]", type: SocialNetworkType.INSTAGRAM },
  { icon: FaFacebook, bgColor: "bg-[#1877F2]", type: SocialNetworkType.FACEBOOK },
];

const SocialButtons = ({ disabled, onSelect }) => {
  return (
    <div className="flex flex-col space-y-3 w-full justify-self-center">
      {socialNetworksDataMap.map(({ icon: Icon, bgColor, type }) => (
        <button
          key={type}
          className={`flex items-center justify-center gap-2 w-full px-4 py-2 rounded-md text-white hover:opacity-90 transition disabled:opacity-50 disabled:cursor-not-allowed font-bold ${bgColor}`}
          disabled={disabled}
          onClick={() => onSelect(type)}
        >
          <Icon className="w-6 h-6 text-white" />
          Continue with {SocialNetworkType.toReadable(type)}
        </button>
      ))}
    </div>
  );
};

export default SocialButtons;
