import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";

import {
  formatDateWithOrdinal,
  truncateContent,
  copyToClipboardHandler,
} from "../../utils/common";
import KbStatusType from "../../data/enums/Kb_statusType";
import { ReactComponent as ClipboardIcon } from "../../assets/clipboard.svg";
import delete_data from "../../assets/delete_data_filter.svg";
import StatusBadge from "../common/StautsBagde";
import FileList from "../FileList/FileList";

function KnowledgeBaseCard({
  organizationskuuid,
  knowledgeBaseData,
  deleteKnowledgeBaseHandler,
}) {
  const navigate = useNavigate();
  const [kbIdCopied, setKbIdCopied] = useState(null);

  const files = knowledgeBaseData?.files || [];
  const kbNameTruncated = truncateContent(
    knowledgeBaseData.title,
    30
  ).truncatedText;

  const maxDFDisplayed = 3;
  const displayedFiles = files.slice(0, maxDFDisplayed);

  const copyKBIdHandler = (e) => {
    e.stopPropagation();
    copyToClipboardHandler(knowledgeBaseData.id, setKbIdCopied);
  };

  const borderMap = {
    [KbStatusType.EMPTY]: "border border-2 border-gray-200",
    [KbStatusType.PROCESSING]: "border border-2 border-gray-200",
    [KbStatusType.READY]: "border border-2 border-main-blue-light",
    [KbStatusType.FAILED]: "border border-2 border-gray-200",
  };

  const isProcessing = knowledgeBaseData.status === KbStatusType.PROCESSING;
  const cardBorder = borderMap[knowledgeBaseData.status] || "";

  return (
    <div
      key={knowledgeBaseData.id}
      className={`relative flex flex-col gap-2 items-start rounded-md shadow-lg w-full max-w-[300px] min-h-[250px] p-4 cursor-pointer ${cardBorder}`}
      onClick={() => {
        navigate(
          `/mkt/organizations/${organizationskuuid}/resources/knowledge-bases/edit`,
          {
            state: { kb: knowledgeBaseData },
          }
        );
      }}
    >
      {isProcessing && (
        <div className="absolute top-0 left-0 w-full h-1 bg-gray-200 rounded-t-md">
          <div className="h-full bg-gray-500 rounded-t-md w-1/2"></div>
        </div>
      )}

      <div
        className="w-full text-lg md:text-xl font-bold text-[#6B6B6B] break-words overflow-hidden text-ellipsis mt-2 mb-1 line-clamp-2 flex items-start"
        title={knowledgeBaseData.title}
      >
        <span>
          {kbNameTruncated}
          {knowledgeBaseData.status && (
            <StatusBadge status={knowledgeBaseData.status} />
          )}
        </span>
      </div>

      <div className="w-full text-xs md:text-xs text-[#6B6B6B] font-bold py-1 mb-1">
        Created on {formatDateWithOrdinal(knowledgeBaseData.created_at) ?? "-"}
      </div>

      <div className="w-full flex-grow">
        <FileList files={displayedFiles} isLoadingVisible={false} />
      </div>

      <div className="w-full bottom-3 flex  justify-end gap-2">
        <FaPencilAlt className="text-gray-600 h-4 w-4" />
        <img
          src={delete_data}
          alt="Delete"
          className="cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            deleteKnowledgeBaseHandler(knowledgeBaseData.sk);
          }}
        />
        <button onClick={copyKBIdHandler} title="Copy knowledge base ID">
          <ClipboardIcon className="h-5 w-5" />
        </button>
        {kbIdCopied && (
          <div className="absolute bottom-3 right-3 text-xs text-green-600">
            Copied!
          </div>
        )}
      </div>
    </div>
  );
}

export default KnowledgeBaseCard;
