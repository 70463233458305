import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import Button from "./common/Button";
import CreateCampaignModal from "./Campaigns/CreateCampaignModal";
import CampaignSearch from "./Campaigns/CampaignSearch";
import * as NotificationService from "../utils/notificationService";
import CampaignsTable from "./Campaigns/CampaignsTable";
import { fetchProjectCampaigns, deleteCampaign } from "../services/index";
import DeleteConfirmationModal from "./common/modals/DeleteConfirmationModal";
import { extractPkSk } from "../utils/common";

function Campaigns({ className }) {
  const queryClient = useQueryClient();
  const { projectskuuid, organizationskuuid } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const campaignsQuery = useQuery({
    queryKey: [`project-${projectskuuid}-campaigns`],
    queryFn: () => fetchProjectCampaigns(projectskuuid, organizationskuuid),
    staleTime: 10 * 1000,
  });

  const deleteCampaignHandler = async () => {
    try {
      const { skuuid: campaignDeletedSkuuid } = extractPkSk(deletingId);
      await deleteCampaign(deletingId, organizationskuuid);
      NotificationService.notifySuccess("Campaign deleted successfully");
      queryClient.setQueryData(
        [`project-${projectskuuid}-campaigns`],
        (oldData) => {
          const updatedPosts =
            oldData?.filter((c) => c.id !== deletingId) || [];
          return updatedPosts;
        }
      );
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === `campaign-${campaignDeletedSkuuid}` ||
          query.queryKey[0] === `campaign-${campaignDeletedSkuuid}-posts`,
      });
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to delete campaign: ${errorMessage}`
      );
    } finally {
      setDeletingId(null);
      setIsConfirmModalOpen(false);
    }
  };

  const onClickDeleteOption = (id) => {
    setDeletingId(id);
    setIsConfirmModalOpen(true);
  };

  const filteredCampaigns = campaignsQuery?.data.filter((campaign) => {
    const searchTerm = searchInput.toLowerCase();
    return campaign.title.toLowerCase().includes(searchTerm);
  });

  return (
    <div
      className={
        className +
        " flex flex-col rounded-md shadow shadow-yet-yet-another-gray"
      }
    >
      <div className="flex flex-row p-4 justify-between">
        <h1 className="text-2xl font-bold">Campaigns</h1>
        <CampaignSearch
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <div>
          <Button onClick={() => setOpenModal(true)}>Create</Button>
        </div>
      </div>
      <div className="h-[calc(100vh-150px)] overflow-y-auto">
        <CampaignsTable
          organizationskuuid={organizationskuuid}
          campaigns={filteredCampaigns}
          deleteCampaignHandler={onClickDeleteOption}
        />
      </div>
      <CreateCampaignModal
        organizationskuuid={organizationskuuid}
        projectskuuid={projectskuuid}
        openModal={openModal}
        setOpenModal={setOpenModal}
        refetch={campaignsQuery.refetch}
      />
      <DeleteConfirmationModal
        showModal={isConfirmModalOpen}
        setOpenModal={setIsConfirmModalOpen}
        confirmDelete={deleteCampaignHandler}
        entityName="Campaign"
        subEntityName="posts"
      />
    </div>
  );
}

export default Campaigns;
