import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa"; // Ícono de carga de Font Awesome

function PercentageLoader({ isLoading, isImageReady, setIsLoading }) {
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(
        () => {
          setLoadingPercentage((prev) => {
            if (isImageReady && prev < 100) {
              return Math.min(prev + 5, 100);
            }
            if (!isImageReady && prev < 100) {
              return Math.min(prev + 1, 100);
            }
            if (prev >= 100) {
              clearInterval(interval);
              setIsLoading(false);
              return 100;
            }
            return prev;
          });
        },
        isImageReady ? 50 : 150
      );

      return () => clearInterval(interval);
    }
  }, [isLoading, isImageReady, setIsLoading]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-48 p-6 bg-white shadow-lg rounded-2xl dark:bg-gray-900">
      <FaSpinner className="w-10 h-10 text-blue-500 animate-spin mb-3" />
      <p className="text-lg text-gray-700 dark:text-gray-300 font-medium">
        Generating post...
      </p>
      <div className="w-full flex justify-center mt-4">
        <div className="w-4/5 h-3 bg-gray-300 rounded-full dark:bg-gray-700 shadow-inner">
          <div
            className="h-full bg-blue-500 rounded-full transition-all duration-200 ease-in-out"
            style={{ width: `${loadingPercentage}%` }}
          ></div>
        </div>
      </div>
      <p className="text-lg text-gray-700 dark:text-gray-300 mt-2 font-semibold">
        {loadingPercentage}%
      </p>
    </div>
  );
}

export default PercentageLoader;
