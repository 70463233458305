import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { FaPencilAlt, FaTimesCircle, FaSave } from "react-icons/fa";

import SocialNetworkType from "../../data/enums/socialNetworkType";
import { stateMap } from "../../data/CampaignStates";
import {
  formatDateString,
  removeFirstCharacter,
  toInt,
} from "../../utils/common";
import { updateTargetPlatformMetrics } from "../../services";
import { isEmpty, isValidMetric } from "../../utils/validations";
import * as NotificationService from "../../utils/notificationService";
import { socialPlatformIconsMap } from "../../utils/socialPlatformUtils";

const TargetRow = ({ currentTarget, editingRow, toggleEditRow }) => {
  const queryClient = useQueryClient();
  let { projectskuuid } = useParams();

  const isLinkedinPost =
    currentTarget.social_network === SocialNetworkType.LINKEDIN;
  const isEditingRow = editingRow === currentTarget.sk;
  const targetMetrics = currentTarget.metrics?.metrics;

  const currentViews = !isEmpty(targetMetrics)
    ? targetMetrics?.impression_count
    : "0";
  const currentReactions = !isEmpty(targetMetrics)
    ? targetMetrics?.like_count
    : "0";
  const currentComments = !isEmpty(targetMetrics)
    ? targetMetrics?.reply_count
    : "0";

  const [currentMetrics, setCurrentMetrics] = useState({
    views: currentViews,
    reactions: currentReactions,
    comments: currentComments,
  });
  const [initialMetrics, setInitialMetrics] = useState({
    views: currentViews,
    reactions: currentReactions,
    comments: currentComments,
  });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    setInitialMetrics({
      views: currentViews,
      reactions: currentReactions,
      comments: currentComments,
    });
    setCurrentMetrics({
      views: currentViews,
      reactions: currentReactions,
      comments: currentComments,
    });
  }, [currentViews, currentReactions, currentComments]);

  const handleChange = (e) => {
    let targetValue = e.target.value;
    if (targetValue.length > 0 && targetValue[0] === "0") {
      targetValue = removeFirstCharacter(targetValue);
    }
    setCurrentMetrics({
      ...currentMetrics,
      [e.target.name]: targetValue === "" ? "0" : targetValue,
    });
  };

  const handleCancel = () => {
    setCurrentMetrics(initialMetrics);
    toggleEditRow(null);
  };

  const handleSubmit = async () => {
    try {
      for (const metric in currentMetrics) {
        isValidMetric(currentMetrics[metric]);
      }
      const payloadMetrics = {
        metrics: {
          impression_count: toInt(currentMetrics.views),
          like_count: toInt(currentMetrics.reactions),
          reply_count: toInt(currentMetrics.comments),
        },
        id: `${currentTarget.pk}&${currentTarget.sk}`,
      };
      await updateTargetPlatformMetrics(payloadMetrics);
      queryClient.invalidateQueries([`project-${projectskuuid}-campaigns`]);
      NotificationService.notifySuccess("Metrics added successfully");
      toggleEditRow(null);
      setInitialMetrics(currentMetrics);
    } catch (error) {
      console.error("Failed to add metrics: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(`Failed to add metrics: ${errorMessage}`);
    }
  };

  const inputProps = {
    className: "w-10 p-0 pl-1 pr-1 border border-gray-300 rounded",
    disabled: !isLinkedinPost,
    min: "0",
    style: {
      WebkitAppearance: "none",
      MozAppearance: "textfield",
    },
    onChange: handleChange,
  };

  const Icon = socialPlatformIconsMap[currentTarget.social_network];

  return (
    <React.Fragment>
      <tr
        className={`hover:bg-light-blue hover:cursor-pointer border-b bg-gray-100`}
      >
        <td className={`py-3 px-4 pl-20 w-60`}>
          <div className="h-20 flex-col justify-center items-start gap-1 inline-flex">
            <div className="self-stretch text-black text-lg font-semibold leading-normal tracking-tight flex items-center">
              <Icon className="h-5 w-5 text-gray-700 mx-2" />
              {`${
                currentTarget.social_account_name ??
                currentTarget.social_network
              }`}
            </div>
            <div className="self-stretch text-light-gray text-base font-normal leading-snug tracking-tight">
              {formatDateString(
                currentTarget.published_at ||
                  currentTarget.schedule_time ||
                  currentTarget.created_at
              )}
            </div>
          </div>
        </td>
        <td className="py-3 px-4 w-32">
          <div
            className={
              "h-6 px-3 rounded justify-start items-center gap-1.5 inline-flex " +
              stateMap[currentTarget.status].classNameOuter
            }
          >
            <div
              className={
                "text-center text-sm font-normal leading-tight " +
                stateMap[currentTarget.status].classNameInner
              }
            >
              {stateMap[currentTarget.status].title}
            </div>
          </div>
        </td>
        {["views", "reactions", "comments"].map((metric) => (
          <td key={metric} className="py-3 px-4 w-10">
            {isEditingRow ? (
              <input
                {...inputProps}
                value={currentMetrics[metric]}
                name={metric}
              />
            ) : (
              <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
                {currentMetrics[metric]}
              </span>
            )}
          </td>
        ))}

        <td className="py-8 px-5 w-20">
          {isEditingRow ? (
            <div className="flex justify-start items-center space-x-7">
              <FaTimesCircle
                onClick={handleCancel}
                className="text-gray-600 hover:text-black"
                size={20}
              />
              <FaSave
                onClick={handleSubmit}
                className="text-gray-600 hover:text-black"
                size={20}
              />
            </div>
          ) : (
            <div className="flex justify-start items-center space-x-7">
              {isLinkedinPost && (
                <button
                  className="cursor-pointer"
                  disabled={!isLinkedinPost}
                  onClick={() => toggleEditRow(currentTarget.sk)}
                  title="Edit metrics"
                >
                  <FaPencilAlt
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    size={20}
                    className="text-gray-600 hover:text-black"
                    value="aaaa"
                  />
                </button>
              )}
            </div>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default TargetRow;
