import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { ReactComponent as ArrowDropDownIcon } from "../assets/arrow_drop_down.svg";
import { AuthContext } from "../AuthProvider";
import { getOrganizationById } from "../services";
import CreditsCounter from "../components/organizations/CreditsCounter";

export default function UserMenu({orgId}) {
  const auth = useContext(AuthContext);
  const { organizationskuuid: orgSkuuid } = useParams();
  const [showDropDown, setShowDropDown] = useState(false);

  const { data: orgData } = useQuery({
    queryKey: ["organization", orgSkuuid],
    queryFn: () => {
      if (!orgId || !auth.user) return null;
      return getOrganizationById(orgId);
    },
    enabled: !!orgSkuuid,
  });

  const toggleDropDown = () => setShowDropDown((prev) => !prev);

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setShowDropDown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  if (!auth.user) return null;


  return (
    <div className="fixed top-0 right-0 z-50 pr-4 flex justify-end items-center gap-4">
      <CreditsCounter organizationSkuuid={orgSkuuid} orgData={orgData}/>
      <div className="relative" ref={ref}>
        <button
          onClick={toggleDropDown}
          className="flex items-center gap-2 px-4 py-2 transition-shadow min-w-[180px] h-12"
          aria-label="User menu"
        >
          <div className="w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full text-sm">
            {auth.user.given_name[0]}
            {auth.user.family_name[0]}
          </div>
          <span className="text-sm font-medium text-black truncate">
            {auth.user.given_name} {auth.user.family_name}
          </span>
          <ArrowDropDownIcon
            className="w-4 h-4 text-gray-500 hover:text-gray-900 transition-colors"
          />
        </button>

        {showDropDown && (
          <div className="absolute right-0 mt-2 w-40 bg-white rounded-lg shadow-xl border border-gray-100">
            <button
              onClick={() => auth.signout()}
              className="w-full px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 text-left transition-colors"
            >
              Sign out
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
