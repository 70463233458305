import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";
import { extractPkSk } from "../utils/common";

export const createPromptTemplate = async (orgId, promptName, promptDescription, promptSource) => {
  try {
    const organizationskuuid = extractPkSk(orgId).skuuid;
    const session = await fetchAuthSession();
    const newTemplate = {
        title: promptName,
        prompt_template_content: promptDescription,
        ...(promptSource && { prompt_template_reference: promptSource }),
      organization_id: orgId
    };

    const response = await fetch(
      `${API_SERVER}/organization/${organizationskuuid}/custom_template`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(newTemplate),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to save prompt template:", error);
    throw error;
  }
};

export const fetchPromptTemplates = async (orgSkuuid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${orgSkuuid}/custom_template`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Error fetchPromptTemplates:", error);
    throw error;
  }
};

export const updatePromptTemplate = async (orgSkuuid, promptSkuuid, promptName, promptDescription, promptSource) => {
  try {
    const session = await fetchAuthSession();
    const newTemplate = {
        title: promptName,
        prompt_template_content: promptDescription,
    };
    const response = await fetch(
      `${API_SERVER}/organization/${orgSkuuid}/custom_template/${promptSkuuid}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(newTemplate),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Error updating template:", error);
    throw error;
  }
};

export const deletePromptTemplate = async (orgSkuuid, promptSkuuid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${orgSkuuid}/custom_template/${promptSkuuid}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Error deletePromptTemplate:", error);
    throw error;
  }
};
