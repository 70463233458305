import { useMutation } from "@tanstack/react-query";

import { uploadFileToS3 } from "../../services";


export function useUploadFile() {
  return useMutation({
    mutationFn: (data) => uploadFileToS3(data.file, data.presigned_url)
  })
}
