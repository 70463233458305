import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteOrganizationResourceImages,
  getOrganizationResourceImages,
  postOrganizationResourceImages,
} from "../../services/OrganizationResourceService";
import { extractPkSk } from "../../utils/common";
import { toBase64 } from "../../components/designStudio/utils";
import * as NotificationService from "../../utils/notificationService";

const queryClient = new QueryClient();

export const useGetImages = (organizationId) => {
  return useQuery({
    queryKey: ["customImages", organizationId],

    queryFn: async () =>
      await getOrganizationResourceImages(organizationId, "img"),

    select: (data) => {
      const customImages = data.map((img) => {
        const { skuuid } = extractPkSk(img.id);
        return {
          id: `IMG#${skuuid}`,
          url: img.content,
        };
      });
      return customImages;
    },

    suspense: false,
  });
};

export const useSaveImages = (organizationId, campaignId, postId) => {
  return useMutation({
    mutationFn: async (image) => {
      image = await toBase64(image);
      return await postOrganizationResourceImages(
        organizationId,
        campaignId,
        postId,
        image
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["customImages", organizationId]);
      NotificationService.notifySuccess("Image uploaded successfully");
    },
    onError: (error) => {
      NotificationService.notifyError("Failed to upload image");
    },
  });
};

export const useDeleteImages = (organizationId) => {
  return useMutation({
    mutationFn: async (resourceId) =>
      await deleteOrganizationResourceImages(organizationId, resourceId),
    onSuccess: () => {
      queryClient.invalidateQueries(["customImages", organizationId]);
      NotificationService.notifySuccess("Image deleted successfully");
    },
    onError: (error) => {
      NotificationService.notifyError("Failed to delete image");
    },
  });
};
