import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { validateInput, validateEmailInput } from "../../utils/validations";
import CustomInput from "../common/forms/CustomInput";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import { AuthContext } from "../../AuthProvider";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";

export default function SignInForm({ }) {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const initialErrors = { email: "", password: "", reason: "" };
  const initialUser = { email: "", password: "" };
  const [errors, setErrors] = useState(initialErrors);
  const [user, setUser] = useState(initialUser);

  const handleInputChange = (field, errorMessage) => (e) => {
    const value = e.target.value;
    validateInput(value, field, setErrors, errorMessage);
    if (field === "email") {
      validateEmailInput(value, field, setErrors);
    }
    setUser((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleSignIn = async () => {
    const result = await auth.signInWithCredentials(user.email, user.password);
    if (result.success) {
      navigate(`/auth/callback`);
    } else {
      setErrors((prevState) => ({ ...prevState, reason: result.reason }));
    }
  }


  return (
    <>
      <div className="items-center items-baseline">
        <div className="relative py-3">
          <CustomInput
            id={`email`}
            type="text"
            label="Email *"
            value={user.email}
            error={errors.email}
            onChange={handleInputChange("email", "Email is required.")}
            bgColorClass=""
          />
        </div>
        <div className="relative py-3">
          <CustomInput
            id={`password`}
            type="password"
            label="password *"
            value={user.password}
            error={errors.password}
            onChange={handleInputChange("password", "password is required.")}
            bgColorClass=""
          />
        </div>

        <div className="my-4">
          <ActionButtonsGroup
            saveLabel="Sign in"
            bgColorClass=""
            saveDisabled={
              (errors.email.trim().length !== 0 || errors.password.trim().length !== 0) ||
              (user.email.trim().length === 0 || user.password.trim().length === 0)
            }
            onSave={handleSignIn}
          />
          <InputErrorDisplayer message={errors.reason} />
        </div>
      </div>
    </>
  );
}
