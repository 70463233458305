import React, { useCallback } from "react";
import * as NotificationService from "../../utils/notificationService";
import { ReactComponent as PDFIcon } from "../../assets/pdf_icon.svg";
import { ReactComponent as DocxIcon } from "../../assets/docx_icon.svg";
import { ReactComponent as TxtIcon } from "../../assets/txt_icon.svg";
import { ReactComponent as CsvIcon } from "../../assets/csv_icon.svg";
import { ReactComponent as XlsxIcon } from "../../assets/xlsx_icon.svg";
import TrashButton from "../common/buttons/TrashButton";
import DragDropFile from "../DragDropFile/DragDropFile";
import { validateFileSizeNType } from "../../utils";


const FileUploader = ({
  maxFiles = 15,
  maxFileSizeMB = 10,
  onFilesChange,
  initialFiles = [],
  readonly = false,
  selectedFiles,
  setSelectedFiles,
}) => {
  const handleValidFiles = useCallback(
    (files) => {
      const validFiles = validateFileSizeNType(files, maxFileSizeMB * 1024 * 1024);

      if (validFiles.length + selectedFiles.length > maxFiles) {
        NotificationService.notifyError(
          `You can only upload a maximum of ${maxFiles} files.`
        );
        return;
      }

      const newFiles = validFiles.filter(
        (file) => !selectedFiles.some((f) => f.name === file.name)
      );

      const updatedFiles = [...selectedFiles, ...newFiles].slice(0, maxFiles);
      setSelectedFiles(updatedFiles);
      onFilesChange(updatedFiles);
    },
    [maxFiles, maxFileSizeMB, onFilesChange, selectedFiles]
  );

  const handleFileSelection = useCallback(
    (event) => {
      const files = Array.from(event.target.files);
      handleValidFiles(files);
      event.target.value = "";
    },
    [handleValidFiles]
  );

  const handleFileDrop = (e) => {
    const files = Array.from(e.dataTransfer.files);
    handleValidFiles(files);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    onFilesChange(updatedFiles);
  };

  const getIconForFile = (file) => {
    const fileName = typeof file === "string" ? file : file.name;

    if (fileName.endsWith(".pdf")) return <PDFIcon className="w-6 h-6" />;
    if (fileName.endsWith(".docx") || fileName.endsWith(".doc"))
      return <DocxIcon className="w-6 h-6" />;
    if (fileName.endsWith(".txt")) return <TxtIcon className="w-6 h-6" />;
    if (fileName.endsWith(".csv")) return <CsvIcon className="w-6 h-6" />;
    if (fileName.endsWith(".xlsx")) return <XlsxIcon className="w-6 h-6" />;
    return null;
  };

  return (
    <div className="flex w-full space-x-4 justify-between">
      <div className="flex-grow">
        <DragDropFile
          onDrop={handleFileDrop}
          onChange={handleFileSelection}
        />
      </div>

      <div className="w-1/2 bg-white border rounded-lg shadow p-4">
        <h3 className="text-lg font-semibold mb-2">
          Files Uploaded ({selectedFiles.length})
        </h3>
        {selectedFiles.length === 0 ? (
          <p className="text-gray-500 text-sm">No files uploaded yet.</p>
        ) : (
          <ul className="space-y-2">
            {selectedFiles.map((file, index) => (
              <li
                key={index}
                className="flex items-center justify-between p-2 border rounded-md"
              >
                <div className="flex items-center">
                  {getIconForFile(file)}
                  <span className="ml-2 text-gray-800 text-sm">
                    {file.name}
                  </span>
                </div>
                {!readonly && (
                  <TrashButton
                    label={`Delete ${file.name}`}
                    onClick={() => handleRemoveFile(index)}
                  />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
