import React, { useState } from "react";
import { validateInput } from "../../utils/validations";
import * as NotificationService from "../../utils/notificationService";
import {
  socialPlatformConnectCallbacksMap,
  getSocialPlatformName,
} from "../../utils/socialPlatformUtils";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";
import { getMyRole } from "../../services";
import { useParams } from "react-router-dom";
import SocialButtons from "../SocialButtons";
import BaseModal from "../common/modals/BaseModal";

const AddSocialAccountModal = ({ openModal, setOpenModal }) => {
  const initialErrors = {
    account_name: "",
  };
  const [errors, setErrors] = useState(initialErrors);
  const [inputValid, setInputValid] = useState({
    account_name: true,
  });
  const initialAccount = {
    account_name: "",
  };
  const [newAccount, setNewAccount] = useState(initialAccount);
  const { organizationskuuid } = useParams();

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter((prevState) => ({ ...prevState, [field]: value }));
    validateInput(value, field, setErrors, errorMessage);
  };

  const connectSocialAccountHandler = async (socialNetworkSelected) => {
    try {
      const isAccountNameValid = newAccount.account_name.trim() !== "";
      const isSocialNetworkValid = socialNetworkSelected !== "";

      setInputValid({
        account_name: isAccountNameValid,
      });

      if (!isAccountNameValid || !isSocialNetworkValid) {
        return;
      }

      await getMyRole(organizationskuuid);

      socialPlatformConnectCallbacksMap[
        getSocialPlatformName(socialNetworkSelected)
      ]?.(newAccount.account_name);

      setOpenModal(false);
    } catch (error) {
      console.error("Failed to connect social account: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to connect social account: ${errorMessage}`
      );
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setNewAccount(initialAccount);
    setErrors(initialErrors);
  };

  return (
    <BaseModal
      headerMessage="Add Account"
      showModal={openModal}
      isDismissible={true}
      size="lg"
      handleClose={handleCancel}
      isThereModalFooter={false}
    >
      <div className="space-y-6">
        <div className="relative">
          <input
            autoFocus
            type="text"
            id="account_name"
            value={newAccount.account_name}
            onChange={handleInputChange(
              setNewAccount,
              "account_name",
              "Account name is required."
            )}
            className={`relative ${!inputValid.account_name
                ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
              }`}
            placeholder=" "
          />
          <label
            htmlFor="account_name"
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Account name *
          </label>
        </div>
        <InputErrorDisplayer message={errors.account_name} />
        <SocialButtons
          disabled={newAccount.account_name.trim().length === 0}
          onSelect={(socialNetworkSelected) => {
            connectSocialAccountHandler(socialNetworkSelected);
          }}
        />
      </div>
    </BaseModal>
  );
};

export default AddSocialAccountModal;
