import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import * as NotificationService from "../../utils/notificationService";
import { fetchLinkedinPages, createLinkedinAccount } from "../../services";
import { ReactComponent as LinkedInIcon } from "../../assets/linkedin_icon.svg";
import BaseModal from "../common/modals/BaseModal";


function SelectLinkedinPageModal({
  organizationskuuid,
  showModal,
  setshowModal,
  linkedinAccessToken,
  linkedinAccountName,
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [page, setPage] = useState({account_id: "", page_type: ""});

  const linkedinPages = useQuery({
    queryKey: ["linkedin-pages"],
    queryFn: async () =>
      await fetchLinkedinPages(organizationskuuid, {
        social_network_type: "LinkedIn",
        access_token: linkedinAccessToken,
      }),
  });

  const handleSave = async () => {
    try {
      if (!page.account_id) throw new Error("You must select a likendin account");

      const paylod = {
        account_id: String(page.account_id),
        page_type: page.page_type,
        access_token: linkedinAccessToken,
        account_name: linkedinAccountName,
        organization_id: organizationskuuid,
      };

      await createLinkedinAccount(organizationskuuid, paylod);
      NotificationService.notifySuccess("Social network connected successfully");
      queryClient.invalidateQueries(["user-social-accounts", organizationskuuid]);
      
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(errorMessage);
    }finally{
      setshowModal(false);
      navigate(`/mkt/organizations/${organizationskuuid}/resources/target-platforms`);
    }
  };

  const handleOnChange = (id, page_type) => {
    if (!id || !page_type) return;

    setPage({
      ...page,
      account_id: id,
      page_type
    });
  }

  const handleCancel = () => {
    setshowModal(false);
    NotificationService.notifyInfo("Add social account process canceled");
    navigate(`/mkt/organizations/${organizationskuuid}/resources/target-platforms`);
  };

  return (
    <BaseModal
      headerMessage="Select Linkedin Page"
      saveLabel="Select"
      showModal={showModal}
      handleSave={handleSave}
      handleClose={handleCancel}
      saveDisabled={!page}
    >
      {linkedinPages.data.map((linkedinPage, idx) => {
        const { id, name, page_type } = linkedinPage;

        return (
          <label
            key={`linkedin-page-${idx}`}
            className="flex items-center px-4 py-2 cursor-pointer rounded-lg transition-all gap-4"
          >
            <input
              type="radio"
              name="page"
              value={id}
              checked={page.account_id === id}
              onChange={() => handleOnChange(id, page_type)}
              className="hidden"
            />

            <LinkedInIcon className="w-5 h-5 flex-none" />

            <span className="flex-initial truncate w-36">{name}</span>

            <span className="relative w-4 h-4 border border-gray-500 rounded-full flex items-center justify-center">
              {page.account_id === id && (
                <span className="w-3 h-3 bg-blue-500 rounded-full"></span>
              )}
            </span>
          </label>
        );
      })}
    </BaseModal>
  );
}

export default SelectLinkedinPageModal;
