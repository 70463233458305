import React, { useRef, useState } from "react";

import { ReactComponent as UploadIcon } from "../../assets/upload_files_icon.svg";

import FileInput from "../common/input/FileInput";


function DragDropFile({ onDrop, onChange }) {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleOnkeyDown = (e) => {
    const action = e.key;
    if (action === "Enter" || action === " ") {
      fileInputRef.current.click();
    }
  };

  const handleOnDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    onDrop(e);
  }

  return (
    <div
      className={`border-2 border-dashed rounded-lg flex flex-col justify-center items-center p-6 text-center cursor-pointer transition-all
        ${
          isDragging
            ? "border-blue-500 bg-blue-100"
            : "border-gray-300 bg-[#F5F7FA]"
        }

      `}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleOnDrop}
      role="button"
      tabIndex={0}
      onKeyDown={handleOnkeyDown}
    >
      <>
        <p className="text-gray-900 font-bold text-lg">
          Drag and drop your files here
        </p>
        <UploadIcon className="w-12 h-12 mt-4" />
      </>
      <FileInput
        onChange={onChange}
        fileInputRef={fileInputRef}
        onClick={() => fileInputRef.current && fileInputRef.current.click()}
        isMultiple={true}
      />
    </div>
  );
}

export default DragDropFile;
