export function navigationMenuReducer(state, action) {
    switch (action.type) {
      case "show_project_items":
        return { ...state, projects: true, resources: false, settings: false };
  
      case "show_resource_items":
        return { ...state, projects: false, resources: true, settings: false };
  
      case "show_setting_items":
        return { ...state, projects: false, resources: false, settings: true };
  
      default:
        return state;
    }
  }
