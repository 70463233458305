import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import notFoundIcon from "../../../assets/icons/forbidden_icon.svg";
import LocalStorageKeys from "../../../data/enums/localStorageKeys";
import useLocalStorage from "../../../hooks/LocalStorage/useLocalStorage";
import { getMyOrganizations } from "../../../services";
import { AuthContext } from "../../../AuthProvider";
import { extractPkSk } from "../../../utils/common";
import Button from "../Button";

const UnauthorizedScreen = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const auth = useContext(AuthContext);

  const [, setOrgId, removeStorageOrgId] = useLocalStorage(
    LocalStorageKeys.ORGANIZATION_ID_KEY,
    null
  );

  const handleFetchOrganizationsAndNavigate = async () => {
    if (!auth?.user) return;


    try {
      const user = `${auth.user.given_name}.${auth.user.family_name}`;

      removeStorageOrgId();

      const myOrgs = await getMyOrganizations(user);

      if (myOrgs.length > 0) {
        setOrgId(myOrgs[0].id);
        const { skuuid } = extractPkSk(myOrgs[0].id);

        queryClient.invalidateQueries(["myOrganizations"]);
        queryClient.invalidateQueries(["organization", myOrgs[0].id]);

        navigate(`/mkt/organizations/${skuuid}/projects`);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[400px] p-8 mt-10">
      <img src={notFoundIcon} alt="Unauthorized" className="w-32 h-32 mb-6" />
      <div className="text-center mb-10">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          401 - Unauthorized
        </h3>
        <p className="text-gray-500 max-w-sm">
          You are not authorized to access this page.
        </p>
      </div>

      <Button onClick={handleFetchOrganizationsAndNavigate}>OK</Button>
    </div>
  );
};

export default UnauthorizedScreen;
