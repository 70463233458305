import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  createTargetPlatform,
  getOrganizationById,
  fetchCampaign,
  fetchCampaignPosts,
  fetchKnowledgeBases,
  fetchPromptTemplates,
  fetchPost,
  fetchApps,
  fetchPostTargetPlatforms,
  fetchMessageByRunId,
  fetchPostMessages,
  sendMessage,
  deletePost,
  updatePost,
} from "../services";
import {
  CampaignHeader,
  CampaignPostsDisplayer,
  PostConversation,
} from "../components/Campaigns/CampaignPage";
import {
  extractPkSk,
  formatDateToYYYYMMDD,
  formatSocialAccountStringToArray,
} from "../utils";
import * as NotificationService from "../utils/notificationService";
import CreatePostModal from "../components/posts/CreatePostModal";
import DeleteConfirmationModal from "../components/common/modals/DeleteConfirmationModal";
import useLocalStorage from "../hooks/LocalStorage/useLocalStorage";
import LocalStorageKeys from "../data/enums/localStorageKeys";
import useCampaignContext from "../hooks/Campaign/useCampaignContext";
import Button from "../components/common/Button";
import StatusType from "../data/enums/statusType";
import CampaignType from "../data/enums/campaignType";
import EmptyStateScreen from "../components/Campaigns/EmptyStateScreen";
import { TabList, CampaignKnowledge, PostPreviewSide } from "../components";


const CampaignPage = () => {
  const queryClient = useQueryClient();
  const { campaignskuuid, projectskuuid, organizationskuuid } = useParams();
  const [itemId, setItemId] = useState(1);
  const [editedPostSkuuid, removeEditedPostSkuuid] = useLocalStorage(
    LocalStorageKeys.EDITED_POST_SKUUID,
    null
  );
  const [orgId] = useLocalStorage(LocalStorageKeys.ORGANIZATION_ID_KEY, null);
  const { pkuuid: ownerUserEmail, skuuid: organizationUuid } =
    extractPkSk(orgId);
  const [selectedPostSkuuid, setSelectedPostSkuuid] =
    useState(editedPostSkuuid);
  const [postReferences, setPostReferences] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { csvData, setCsvData } = useCampaignContext();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [postDeletingId, setPostDeletingId] = useState(null);
  const [knowledgeOption, setKnowledgeOption] = useState(null);

  const isCsvDataEmpty = csvData.length === 0;

  const tabs = [
    {
      id: 1,
      label: "Post",
      isActive: itemId === 1,
    },
    {
      id: 2,
      label: "Knowledge",
      isActive: itemId === 2,
    },
  ];

  const handleOnChangeTabId = (itemId) => {
    setItemId(itemId)
  }

  const organizationQuery = useQuery({
    queryKey: ["organization", organizationUuid],
    queryFn: () => {
      return getOrganizationById(orgId);
    },
    enabled: !!orgId,
  });

  const campaignQuery = useQuery({
    queryKey: [`campaign-${campaignskuuid}`],
    queryFn: () =>
      fetchCampaign(projectskuuid, campaignskuuid, organizationskuuid),
    staleTime: 10 * 1000,
    onError: (error) => {
      NotificationService.notifyError(
        `Failed to fetch campaign: ${error.message}`
      );
    },
  });

  const postsQuery = useQuery({
    queryKey: [`campaign-${campaignskuuid}-posts`],
    queryFn: () => fetchCampaignPosts(campaignskuuid, organizationskuuid),
    staleTime: 10 * 1000,
    onError: (error) => {
      NotificationService.notifyError(
        `Failed to fetch posts: ${error.message}`
      );
    },
    select: (data) => {
      return data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    },
  });

  const postQuery = useQuery({
    queryKey: [`post-${selectedPostSkuuid ?? "no-ready"}`],
    queryFn: () =>
      selectedPostSkuuid
        ? fetchPost(campaignskuuid, selectedPostSkuuid, organizationUuid)
        : {},
    staleTime: 10 * 1000,
    enabled: !!selectedPostSkuuid,
    onError: (error) => {
      NotificationService.notifyError(`Failed to fetch post: ${error.message}`);
    },
  });

  const isValidPost = postsQuery.data?.some(
    (post) => post.sk === postQuery.data?.sk
  );

  const messagesQuery = useQuery({
    queryKey: ["post-messages", selectedPostSkuuid],
    queryFn: () =>
      selectedPostSkuuid
        ? fetchPostMessages(
            campaignskuuid,
            selectedPostSkuuid,
            organizationUuid
          )
        : {},
    enabled: !!selectedPostSkuuid && isValidPost,
    refetchOnWindowFocus: false,
  });

  const knowledgeBasesQuery = useQuery({
    queryKey: ["knowledgeBases", organizationskuuid],
    queryFn: async () => await fetchKnowledgeBases(organizationskuuid),
    staleTime: 10 * 1000,
  });

  const userPromptTemplatesQuery = useQuery({
    queryKey: [`user-prompt-templates`, organizationskuuid],
    queryFn: async () => await fetchPromptTemplates(organizationskuuid),
    staleTime: 10 * 1000,
  });

  const userSocialAccountsQuery = useQuery({
    queryKey: [`user-social-accounts`, organizationskuuid],
    queryFn: async () => await fetchApps(organizationskuuid),
    staleTime: 10 * 1000,
  });

  const postTargetPlatformsQuery = useQuery({
    queryKey: [
      `post-${selectedPostSkuuid ?? "post_id_no_ready"}-platform-targets`,
    ],
    queryFn: () =>
      selectedPostSkuuid ? fetchPostTargetPlatforms(selectedPostSkuuid) : {},
    staleTime: 10 * 1000,
    enabled: !!selectedPostSkuuid,
    onError: (error) => {
      NotificationService.notifyError(
        `Failed to fetch post target platform: ${error.message}`
      );
    },
  });

  const parsePrompt = (prompt, promptContent) => {
    const noQuestions = `Skip further questions.`;

    const text = prompt.endsWith(".")
      ? `${prompt} ${noQuestions}`
      : `${prompt}. ${noQuestions}`;

    return promptContent ? `${promptContent} ${text}` : text;
  };

  const isValidDate = (schedule_date, schedule_time) => {
    const dateString = `${schedule_date} ${schedule_time}`;
    const date = formatDateToYYYYMMDD(dateString);
    if (!date) throw new Error("The date is invalid.");

    return date;
  };

  const createTargetPlatformsFromCSV = async (row, postId) => {
    if (!postId) throw new Error("The post id was not provided.");

    if (!row.social_account_schedules) return;

    const nestedArray = formatSocialAccountStringToArray(
      row.social_account_schedules
    );

    try {
      const targetPlatforms = nestedArray.map((targetPlatform) => {
        const social_account_id = targetPlatform[0];
        const social_network = social_account_id.split("#")[1];

        const date = isValidDate(targetPlatform[1], targetPlatform[2]);

        return {
          social_network: social_network,
          social_account_id: social_account_id,
          schedule_time: date,
          status: StatusType.SCHEDULED,
          post_id: postId,
        };
      });
      await Promise.all(targetPlatforms.map(createTargetPlatform));
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred.";
      throw new Error(errorMessage);
    }
  };

  const deleteProcessedPost = (data) => {
    setCsvData((csvData) => csvData.filter((row) => row.title !== data.title));
  };

  const sendMessageByCSV = async (data, postSkuuid) => {
    try {
      const prompt = parsePrompt(data.prompt);

      const { run_id } = await sendMessage(
        campaignskuuid,
        postSkuuid,
        {
          message: prompt,
          organization_id: organizationUuid,
          owner_email: ownerUserEmail,
        },
        organizationskuuid
      );

      return run_id;
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred.";
      throw new Error(errorMessage);
    }
  };

  const processtasks = async (batchCsvData, posts) => {
    const tasks = batchCsvData.map((row, idx) =>
      (async () => {
        try {
          const { skuuid: postSkuuid } = extractPkSk(posts[idx].id);

          const runId = await sendMessageByCSV(row, postSkuuid);
          const payload = {
            run_id: runId,
            organization_id: organizationUuid,
            owner_email: ownerUserEmail,
          };
          await fetchMessageByRunId(campaignskuuid, postSkuuid, payload);

          await createTargetPlatformsFromCSV(row, posts[idx].id);

          return posts[idx];
        } catch (error) {
          console.error(`Error occurred: ${JSON.stringify(row)} - ${error}`);
          const errorMessage = error.message || "An unexpected error occurred.";
          NotificationService.notifyError(errorMessage);
          throw { post: posts[idx], error };
        } finally {
          deleteProcessedPost(row);
        }
      })()
    );

    const results = await Promise.allSettled(tasks);
    return results;
  };

  const changePostStatus = async (results) => {
    if (results.length === 0) return;

    for (const result of results) {
      try {
        const processedPost = result.value || result.reason?.post;
        if (!processedPost) continue;

        const { skuuid: postSkuuid } = extractPkSk(processedPost.id);
        const post = await fetchPost(
          campaignskuuid,
          postSkuuid,
          organizationskuuid
        );

        const status =
          result.status === "fulfilled"
            ? StatusType.IN_REVIEW
            : StatusType.FAILED;

        const updatedPost = await updatePost(
          post.id,
          { status },
          organizationskuuid
        );
        queryClient.setQueryData(
          [`post-${postSkuuid ?? "no-ready"}`],
          (posts) => ({ ...posts, ...updatedPost })
        );
      } catch (error) {
        const ERROR_MESSAGE = "Error updating status post:";
        NotificationService.notifyError(`${ERROR_MESSAGE} ${error}`);
      }
    }
    postsQuery.refetch();
    messagesQuery.refetch();
    postTargetPlatformsQuery.refetch();
  };

  const searchPostsByBatch = (batchCsvData) => {
    const csvTitles = new Map();
    const batchPost = [];

    for (const post of postsQuery.data) {
      csvTitles.set(post.title, post);
    }

    for (const data of batchCsvData) {
      if (csvTitles.has(data.title)) batchPost.push(csvTitles.get(data.title));
    }

    return batchPost;
  };

  const processCsvData = async () => {
    if (postsQuery.data.length === 0) return;

    const RECORDS_TO_PROCESS = 3;

    for (let i = 0; i < csvData.length; i += RECORDS_TO_PROCESS) {
      const batchCsvData = csvData.slice(i, RECORDS_TO_PROCESS + i);
      const batchPost = searchPostsByBatch(batchCsvData);
      const results = await processtasks(batchCsvData, batchPost);
      await changePostStatus(results);
    }
  };

  useEffect(() => {
    setSelectedPostSkuuid(null);
  }, [campaignskuuid]);

  useEffect(() => {
    if (postsQuery.data?.length > 0) {
      const defaultPost = postsQuery.data[0];
      const { skuuid: postSkuuid } = extractPkSk(defaultPost.id);
      setSelectedPostSkuuid(postSkuuid);
    }
  }, [campaignskuuid]);

  useEffect(() => {
    if (editedPostSkuuid !== null && selectedPostSkuuid) {
      removeEditedPostSkuuid();
    }
  }, [selectedPostSkuuid, editedPostSkuuid, removeEditedPostSkuuid]);

  useEffect(() => {
    if (!isCsvDataEmpty && campaignQuery.data.type === CampaignType.AUTOMATIC) {
      processCsvData();
    }
  }, [campaignQuery.data.type]);

  useEffect(() => {
    if (postQuery.data.knowledge_base_id !== "default") {
      const knowledgeOption = knowledgeBasesQuery.data.find(
        (knowledgeBase) => knowledgeBase.id === postQuery.data.knowledge_base_id
      );
      setKnowledgeOption(knowledgeOption);
    }
  }, [postQuery.data, knowledgeBasesQuery.data]);

  
  if (campaignQuery.isLoading || postsQuery.isLoading) {
    return <div>Loading...</div>;
  }

  const messagesUpdatedCallback = (messages) => {
    const allReferences = extractReferences(messages ?? []);
    setPostReferences(allReferences);
  };

  const extractReferences = (data) => {
    const references = new Set();

    data.forEach((element) => {
      if (element.reference && Array.isArray(element.reference)) {
        element.reference.forEach((ref) => {
          references.add(JSON.stringify(ref));
        });
      }
    });

    return Array.from(references).map((ref) => JSON.parse(ref));
  };

  const deletePostHandler = async () => {
    try {
      const { pkuuid: postDeletedPkuuid, skuuid: postDeletedSkuuid } =
        extractPkSk(postDeletingId);
      await deletePost(postDeletingId, organizationskuuid);
      NotificationService.notifySuccess("Post deleted successfully");

      queryClient.setQueryData(
        [`campaign-${postDeletedPkuuid}-posts`],
        (oldData) => {
          const updatedPosts =
            oldData?.filter((p) => p.id !== postDeletingId) || [];

          if (
            postDeletedSkuuid === selectedPostSkuuid &&
            updatedPosts.length > 0
          ) {
            const newSelectedPost = updatedPosts[0];
            const { skuuid: newPostSkuuid } = extractPkSk(newSelectedPost.id);
            setSelectedPostSkuuid(newPostSkuuid);
          } else if (updatedPosts.length === 0) {
            setSelectedPostSkuuid(null);
          }

          return updatedPosts;
        }
      );

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === `post-${postDeletedSkuuid}` ||
          query.queryKey[0] === `post-${postDeletedSkuuid}-messages`,
      });
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(`Failed to delete post: ${errorMessage}`);
    } finally {
      setPostDeletingId(null);
      setIsConfirmModalOpen(false);
    }
  };

  const onClickDeleteOption = (post) => {
    setPostDeletingId(post.id);
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="flex flex-row w-full">
      <div className="h-full w-full flex flex-col">
        {campaignQuery.data && (
          <CampaignHeader
            organizationskuuid={organizationskuuid}
            campaign={campaignQuery.data}
          />
        )}

        {postsQuery.data?.length === 0 && (
          <EmptyStateScreen setOpenModal={setOpenModal} />
        )}

        {postsQuery.data?.length > 0 && (
          <div className="hidden max-md:100 md:flex">
            <div className="flex flex-col h-full p-4 md:w-[20%]">
              <Button outline onClick={() => setOpenModal(true)}>
                + New Post
              </Button>
              <div className="hidden md:block">
                <CampaignPostsDisplayer
                  posts={postsQuery.data}
                  selectedPost={postQuery.data}
                  setSelectedPostSkuuid={setSelectedPostSkuuid}
                  deletePostHandler={onClickDeleteOption}
                />
              </div>
            </div>
            {selectedPostSkuuid && postQuery.data && (
              <>
                <div className="flex flex-col h-full p-4 md:w-[50%]">
                  <PostConversation
                    post={postQuery.data}
                    messages={messagesQuery}
                    projectPromptTemplates={userPromptTemplatesQuery?.data}
                    messagesUpdatedCallback={messagesUpdatedCallback}
                    organization={organizationQuery.data}
                    knowledgeBaseId={knowledgeOption ? knowledgeOption?.id : "default"}
                  />
                </div>

                <div className="flex flex-col h-full p-4 md:w-[30%]">
                  <TabList
                    items={tabs}
                    itemId={itemId}
                    onClick={handleOnChangeTabId}
                  />
                  {itemId === 1 && (
                    <PostPreviewSide
                      postQuery={postQuery}
                      className="rounded-3xl border border-mid-light-gray shadow-sm basis-[548px] overflow-y-auto min-h-[800px] no-scrollbar"
                      references={postReferences}
                      deletePostHandler={onClickDeleteOption}
                      organizationskuuid={organizationskuuid}
                      organizationSocialAccounts={
                        userSocialAccountsQuery?.data ?? []
                      }
                      postTargetPlatforms={postTargetPlatformsQuery?.data ?? []}
                    />
                  )}

                  {itemId === 2 && (
                    <div className="mt-5">
                      <CampaignKnowledge
                        postuuid={selectedPostSkuuid}
                        knowledgeBases={knowledgeBasesQuery.data}
                        knowledgeOption={knowledgeOption}
                        setKnowledgeOption={setKnowledgeOption}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}

        <div className="md:hidden flex flex-row bg-transparent text-2xl align-middle items-center p-10 max-md:h-[calc(100vh-70px)] text-center">
          This functionality was designed mainly for tablets and desktops
        </div>

        <CreatePostModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setSelectedPostSkuuid={setSelectedPostSkuuid}
          knowledgeBases={knowledgeBasesQuery.data}
          campaignId={`PJ#${projectskuuid}&CP#${campaignskuuid}`}
          refetch={knowledgeBasesQuery.refetch}
        />

        <DeleteConfirmationModal
          showModal={isConfirmModalOpen}
          setOpenModal={setIsConfirmModalOpen}
          confirmDelete={deletePostHandler}
          entityName="Post"
          subEntityName="metrics and data"
        />
      </div>
    </div>
  );
};

export default CampaignPage;
