import { useContext } from "react";
import { AuthContext } from "../../AuthProvider";

import { ReactComponent as GoogleLogo } from "../../assets/google_logo.svg";
import SignInMethodType from "../../data/enums/signInMethodType";
import SignInForm from "./SignInForm";


export default function SignInMethodSelector({ signInMethodType }) {
    const auth = useContext(AuthContext);

    const renderGoogleSignIn = () => (
        <button
            onClick={auth.signin}
            className="self-stretch h-11 mx-auto px-4 bg-white shadow rounded-3xl justify-center items-center gap-4 inline-flex"
        >
            <GoogleLogo />
            <div className="text-center text-black font-semibold text-lg leading-tight">
                Sign in with Google
            </div>
        </button>
    );

    const renderEmailSignIn = () => <SignInForm />;

    switch (signInMethodType) {
        case SignInMethodType.GOOGLE:
            return renderGoogleSignIn();
        case SignInMethodType.EMAIL_PASSWORD:
            return renderEmailSignIn();
        default:
            return null;
    }
}