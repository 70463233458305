import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { FaFolderOpen, FaFolder } from "react-icons/fa";

import { useQuery } from "@tanstack/react-query";

import { extractPkSk, truncateContent } from "../../utils/common";
import { getMyProjects } from "../../services";
import CampaignList from "./CampaignList";


function ProjectsList({ activeProjectId, setActiveProjectId, organizationId, styles }) {
  const { organizationskuuid, projectskuuid } = useParams();

  const myProjectsQuery = useQuery({
    queryKey: ["myProjects", organizationskuuid],
    queryFn: async () => await getMyProjects(organizationId),
    staleTime: 60 * 1000,
  });

  return (
    <ul className="mt-2 list-none text-gray-700 flex pl-4 flex-col overflow-y-auto no-scrollbar">
      {myProjectsQuery.data?.map((pj) => {
        const { skuuid } = extractPkSk(pj.id);
        const isActiveProject = activeProjectId === pj.id;

        return (
          <React.Fragment key={`projects-link-${pj.id}`}>
            <li>
              <NavLink
                to={`organizations/${organizationId}/projects/${skuuid}/campaigns`}
                onClick={() => setActiveProjectId(pj.id)}
                className={(navData) =>
                  `self-stretch h-12 px-4 justify-start items-center gap-6 inline-flex w-full ${styles(navData)}`
                }
              >
                {isActiveProject ? (
                  <FaFolderOpen width={28} height={28} />
                ) : (
                  <FaFolder width={28} height={28} />
                )}
                <div className="grow shrink basis-0 self-stretch py-3 flex-col justify-start items-start gap-0.5 inline-flex">
                  <div className="self-stretch text-sm font-normal leading-normal tracking-wide">
                    {truncateContent(pj.title, 10).truncatedText}
                  </div>
                </div>
              </NavLink>
            </li>
            {isActiveProject && (
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  isActiveProject
                    ? "max-h-80 opacity-100 overflow-y-auto"
                    : "max-h-0 opacity-0"
                }`}
              >
                <CampaignList
                  organizationskuuid={organizationskuuid}
                  projectskuuid={projectskuuid}
                  styles={styles}
                />
              </div>
            )}
          </ React.Fragment>
        );
      })}
    </ul>
  );
}

export default ProjectsList;
