import { useEffect, useRef, useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { processImage, updatePost } from "../../services";
import PercentageLoader from "../common/loaders/PercentageLoader";
import { createStore } from "polotno/model/store";
import { POLOTNO_KEY } from "../../config/constants";
import Workspace from "polotno/canvas/workspace";
import { extractPkSk } from "../../utils/common";
import useLocalStorage from "../../hooks/LocalStorage/useLocalStorage";
import LocalStorageKeys from "../../data/enums/localStorageKeys";
import { useGetImages } from "../../hooks/CustomImages/useCustomImages";
import { updateElementSrc } from "../designStudio/utils";

function Image(props) {
  const queryClient = useQueryClient();
  const prevPromptRef = useRef(props.post.image_prompt);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageReady, setIsImageReady] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [imageMessageError, setImageMessageError] = useState(null);
  const workspaceRef = useRef(null);
  const [store] = useState(() =>
    createStore({ key: POLOTNO_KEY, showCredit: true })
  );
  const [orgId] = useLocalStorage(LocalStorageKeys.ORGANIZATION_ID_KEY, null);
  const { pkuuid: ownerUserEmail, skuuid: organizationUuid } =
    extractPkSk(orgId);

  const { pkuuid: campaignSkuuid, skuuid: postSkuuid } = extractPkSk(
    props.post.id
  );

  const { data: customImages = [] } = useGetImages(organizationUuid);

  const processImageMutation = useMutation({
    mutationFn: async (payload) => {
      try {
        setIsLoading(true);
        setIsImageReady(false);
        setImageError(false);

        const imageObj = await processImage(
          campaignSkuuid,
          postSkuuid,
          payload
        );
        queryClient.invalidateQueries(["organization", organizationUuid]);
        queryClient.setQueryData([`post-${postSkuuid}`], (old) => {
          return { ...old, image: imageObj.image };
        });

        if (props.post.edit_state) {
          const newState = {
            ...props.post.edit_state,
            pages: props.post.edit_state.pages.map((page) => {
              if (page.id === props.post.edit_state.pages[0].id) {
                return {
                  ...page,
                  children: page.children.map((children) => {
                    if (children.type === "image") {
                      children.src = updateElementSrc(
                        children,
                        customImages,
                        imageObj
                      );
                    }
                    return children;
                  }),
                };
              }
              return page;
            }),
          };

          store.loadJSON(newState);

          await new Promise((resolve) => setTimeout(resolve, 100));

          const pageId = props.post.edit_state.pages[0].id;
          const updatedImageDataURL = await store.toDataURL({
            pageId,
            mimeType: "image/jpg",
          });

          const postPayload = {
            edit_state: newState,
            edit_image: updatedImageDataURL,
          };

          await updatePost(props.post.id, postPayload, organizationUuid);

          queryClient.invalidateQueries([`post-${postSkuuid}`]);
        }

        setIsImageReady(true);
      } catch (error) {
        const errorMessage = error.message || "An unexpected error occured";
        setImageMessageError(errorMessage);
        setImageError(true);
      } finally {
        setIsLoading(false);
      }
    },
    onError: () => {
      setIsLoading(false);
      setImageError(true);
    },
  });

  useEffect(() => {
    if (
      !props.post.image ||
      props.post.image_prompt !== prevPromptRef.current
    ) {
      const payload = {
        prompt: props.post.image_prompt,
        ownerUserEmail: ownerUserEmail,
        organizationUuid: organizationUuid,
      };
      processImageMutation.mutate(payload);
    }
  }, [props.post.image_prompt]);

  return (
    <div>
      {isLoading ? (
        <PercentageLoader
          isLoading={isLoading}
          isImageReady={isImageReady}
          setIsLoading={setIsLoading}
        />
      ) : (
        <div className="relative flex flex-row justify-center">
          <img
            src={props.post.edit_image || props.post.image}
            alt={props.post.image_prompt}
            onLoad={() => setIsImageReady(true)}
            onError={() => {
              setImageError(true);
              setIsImageReady(false);
            }}
            style={{
              display: imageError ? "none" : "block",
            }}
          />
          {imageError && (
            <div className="flex flex-col lg:items-center">
              <div className="text-red-500 text-sm lg:text-base">
                Error loading image
              </div>
              <div className="text-red-400 text-sm lg:text-base">
                {imageMessageError}
              </div>
            </div>
          )}
        </div>
      )}
      <div style={{ width: "0.1%", height: "0.1px" }} ref={workspaceRef}>
        <Workspace store={store} />
      </div>
    </div>
  );
}

export default Image;
