import { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { ADD_BLUE_ICON_URL } from "../../config/ExternalResources";
import * as NotificationService from "../../utils/notificationService";
import { fetchKnowledgeBases, deleteKnowledgeBase} from "../../services";
import KnowledgeBaseCard from "./KnowledgeBaseCard";
import LoadingCard from "../common/LoadingCard";
import DeleteConfirmationModal from '../common/modals/DeleteConfirmationModal';


function KnowledgeBase({ className }) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { organizationskuuid } = useParams();
  let newKbCreated = location.state?.newKbCreated;
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const handleCreateNewClick = () => {
    navigate(`/mkt/organizations/${organizationskuuid}/resources/knowledge-bases/create`);
  };

  const handleNewKnowledgeBase = (knowledgeBasesResponse) => {
    if (newKbCreated) {
      const exists = knowledgeBasesResponse.some((kb) => kb.id === newKbCreated.id);
      if (!exists) {
        knowledgeBasesResponse.push(newKbCreated);
      }
      newKbCreated = null;
    }
    return knowledgeBasesResponse;
  };

  const knowledgeBasesQuery = useQuery({
    queryKey: ["knowledgeBases", organizationskuuid],
    queryFn: async () => {
      const response = await fetchKnowledgeBases(organizationskuuid);
      return handleNewKnowledgeBase(response);
    },
    staleTime: 10 * 1000,
    onError: (error) => {
      NotificationService.notifyError(
        `Failed to fetch knowledge bases: ${error.message}`
      );
    },
  });

  const onClickDeleteOption = (kbId) => {
    setDeletingId(kbId);
    setIsConfirmModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const skuuid = deletingId.split("#")[1];
      await deleteKnowledgeBase(organizationskuuid, skuuid);
      NotificationService.notifySuccess("Knowledge base deleted successfully");
      queryClient.setQueryData(
        [`knowledgeBases`, organizationskuuid],
        (oldData) => {
          const updatedKbs = oldData?.filter((kb) => kb.sk !== deletingId) || [];
          return updatedKbs;
        }
      );
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(`Failed to delete knowledge base: ${errorMessage}`);
    } finally {
      setIsConfirmModalOpen(false);
      setDeletingId(null);
    }
  };

  return (
    <div
      className={`${className} flex flex-col p-5 `}
    >
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <div onClick={handleCreateNewClick} >
              <div className="flex flex-col justify-center max-w-full bg-white rounded max-w-[300px] min-h-[250px] rounded-md shadow shadow-yet-yet-another-black shadow-lg w-[284px] cursor-pointer">
                <img
                  loading="lazy"
                  src={ADD_BLUE_ICON_URL}
                  className="object-contain self-center w-12 aspect-square"
                  alt="Create New Knowledge Base"
                />
                <div className="mt-5 w-full text-center font-bold text-main-blue">
                  Create New <br />
                  Knowledge Base
                </div>
              </div>
            </div>
            {knowledgeBasesQuery.isLoading ? (
              <LoadingCard />
            ) : (
              knowledgeBasesQuery.data?.map((knowledgeBaseData) => (
                <KnowledgeBaseCard
                  key={knowledgeBaseData.id}
                  organizationskuuid={organizationskuuid}
                  knowledgeBaseData={knowledgeBaseData}
                  deleteKnowledgeBaseHandler={onClickDeleteOption}
                />
              ))
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
          showModal={isConfirmModalOpen}
          setOpenModal={setIsConfirmModalOpen}
          confirmDelete={confirmDelete}
          entityName="Knowledge Base"
          subEntityName="data filters"
        />
    </div>
  );
}

export default KnowledgeBase;
