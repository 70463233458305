import { useMutation } from "@tanstack/react-query";

import { postOrganizationResourceFile } from "../../services";

export function useCreateFile(organizationskuuid) {
  return useMutation({
    mutationFn: (data) => {
      return postOrganizationResourceFile(organizationskuuid, data);
    },
  });
}
