import React, { useEffect, useState } from "react";

import { validateInput } from "../../utils/validations";
import { createPromptTemplate } from "../../services";
import * as NotificationService from "../../utils/notificationService";
import { READABLE_SITE_NAME } from "../../config/constants";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";
import BaseModal from "../common/modals/BaseModal";

const NewTemplate = ({
  organizationId,
  organizationskuuid,
  queryClient,
  closeModal,
  showModal
}) => {
  const [promptName, setPromptName] = useState("");
  const [promptDescription, setPromptDescription] = useState("");
  const initialErrors = {
    name: "",
    description: "",
  };
  const [errors, setErrors] = useState(initialErrors);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);

  useEffect(() => {
    setIsCreateButtonDisabled(!promptName.trim() || !promptDescription.trim());
  }, [promptName, promptDescription]);

  const handleSave = async () => {
    try {
      const newPromptTemplate = await createPromptTemplate(
        organizationId,
        promptName,
        promptDescription
      );
      queryClient.setQueryData(
        ["promptTemplates", organizationskuuid],
        (oldData) => {
          return [...oldData, newPromptTemplate];
        }
      );
      NotificationService.notifySuccess("Prompt template created successfully");
      closeModal();
      setPromptName("");
      setPromptDescription("");
      setErrors(initialErrors);
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to save prompt template: ${errorMessage}`
      );
    }
  };

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  const handleCancel = () => {
    closeModal();
    setPromptName("");
    setPromptDescription("");
    setErrors(initialErrors);
  };

  const validatePromptName = () => {
    validateInput(
      promptName,
      "name",
      setErrors,
      "Prompt template name is required"
    );
  };

  const validatePromptDescription = () => {
    validateInput(
      promptDescription,
      "description",
      setErrors,
      "Prompt template description is required"
    );
  };

  const validateAllInputs = () => {
    validatePromptName();
    validatePromptDescription();
  };

  return (
    <BaseModal
      handleClose={handleCancel}
      headerMessage="New Prompt Template"
      saveLabel="Create"
      saveDisabled={isCreateButtonDisabled}
      handleSave={handleSave}
      handleSaveDisabled={validateAllInputs}
      isDismissible={true}
      showModal={showModal}
    >
      <div className="space-y-6">
        <div>
          <label className="block text-black">Prompt Name *</label>
          <textarea
            autoFocus
            className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
            placeholder="Give your prompt a name that you can easily recognize"
            value={promptName}
            onChange={handleInputChange(
              setPromptName,
              "name",
              "Prompt template name is required"
            )}
          />
          <InputErrorDisplayer message={errors.name} />
        </div>
        <div>
          <label className="block text-black">Prompt Description *</label>
          <textarea
            className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
            rows="6"
            value={promptDescription}
            onBlur={validatePromptDescription}
            onChange={handleInputChange(
              setPromptDescription,
              "description",
              "Prompt template description is required"
            )}
            placeholder={`Write your prompt details as the question you would like to ask ${READABLE_SITE_NAME}’s AI in order to get a fine-tuned response.`}
          />
          <InputErrorDisplayer message={errors.description} />
        </div>
      </div>
    </BaseModal>
  );
};

export default NewTemplate;
