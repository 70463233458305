import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import * as NotificationService from "../../utils/notificationService";
import { validateInput } from "../../utils/validations";
import { createProject } from "../../services";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";
import BaseModal from "../common/modals/BaseModal";


const ProjectModal = ({ organizationskuuid, showModal, closeModal }) => {
  const { orgId } = useOutletContext();
  const queryClient = useQueryClient();
  const [projectTitle, setProjectTitle] = useState("");
  const initialErrors = { title: "" };
  const [errors, setErrors] = useState(initialErrors);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);

  useEffect(() => {
    setIsCreateButtonDisabled(!projectTitle.trim());
  }, [projectTitle]);

  const handleSave = async () => {
    try {
      const payload = {
        title: projectTitle,
        organization_id: orgId
      }
      const createdProject = await createProject(payload, organizationskuuid);
      NotificationService.notifySuccess("Project created successfully");
      queryClient.setQueryData(["myProjects", organizationskuuid], (oldData) => {
        return oldData ? [...oldData, createdProject] : [createdProject];
      });
      closeModal();
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(errorMessage);
    }
  };

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  const handleCancel = () => {
    closeModal();
    setProjectTitle("");
    setErrors(initialErrors);
  };

  const validateProjectTitle = () => {
    validateInput(
      projectTitle,
      "title",
      setErrors,
      "Project title is required"
    );
  };

  const validateAllInputs = () => {
    validateProjectTitle();
  };
  return (
    <BaseModal
      handleClose={handleCancel}
      handleSave={handleSave}
      handleSaveDisabled={validateAllInputs}
      headerMessage="New Project"
      showModal={showModal}
      size="lg"
      saveLabel="Create"
      saveDisabled={isCreateButtonDisabled}
      isDismissible={true}
    >
      <div className="space-y-6">
        <div className="relative">
          <label className="block text-black">Project title *</label>
          <textarea
            autoFocus
            className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
            placeholder="Project title*"
            value={projectTitle}
            onChange={handleInputChange(
              setProjectTitle,
              "title",
              "Project title is required"
            )}
          />
          <InputErrorDisplayer message={errors.title} />
        </div>
      </div>
    </BaseModal>
  );
};

export default ProjectModal;
