import React, { useState } from "react";
import InputErrorDisplayer from "./InputErrorDisplayer";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function CustomInput({
  id,
  type,
  label,
  value,
  error,
  autoFocus,
  placeholder = "",
  bgColorClass = "bg-white",
  onChange = () => { },
  onBlur = () => { },
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <>
      <input
        autoFocus={autoFocus}
        id={id}
        type={type === "password" && isPasswordVisible ? "text" : type}
        className={
          `relative block w-full h-[40px] px-2 pb-2 pt-4 
            text-sm text-gray-900 bg-transparent rounded-lg border-1 
            appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer 
            ${error ? "border-red-500" : "border-gray-300"}`
        }
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label
        htmlFor={id}
        className={
          `absolute text-sm text-gray-500 duration-300 transform -translate-y-4 
            scale-75 top-2 z-10 origin-[0] ${bgColorClass} px-2 peer-focus:px-2 
            peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 
            peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
            peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 
            rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`
        }
      >
        {label}
      </label>
      {type === "password" && (
        <button
          type="button"
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
          onClick={togglePasswordVisibility}
          aria-label={isPasswordVisible ? "Hide password" : "Show password"}
        >
          {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
        </button>
      )}

      <InputErrorDisplayer message={error} />
    </>
  );
}
