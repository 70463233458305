import { useMutation } from '@tanstack/react-query'

import { getUploadPresignedUrls } from '../../services'


export function useGetPresignedUrl() {
  return useMutation({
    mutationFn: (data) => getUploadPresignedUrls(data),
  });
}
