import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";

import NewTemplate from "./NewTemplate";
import EditTemplate from "./EditTemplate";
import { ADD_BLUE_ICON_URL } from "../../config/ExternalResources";
import DeleteConfirmationModal from "../common/modals/DeleteConfirmationModal";
import PromptTemplateCard from "./PrompTemplateCard";
import * as NotificationService from "../../utils/notificationService";
import LoadingCard from "../common/LoadingCard";
import {
  createPromptTemplate,
  deletePromptTemplate,
} from "../../services/index";
import usePromptTemplate from "../../hooks/PromptTemplate/usePromptTemplate";

function PromptTemplate() {
  const queryClient = useQueryClient();
  const { orgId } = useOutletContext();
  const { organizationskuuid } = useParams();
  const {
    setOrgSkuuid,
    promptTemplates,
    isPromptTemplateLoading,
  } = usePromptTemplate();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [deletingSkuuid, setDeletingSkuuid] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setShowModal(true);
  const hideDropdown = () => setActiveDropdown(null);

  const closeModal = () => {
    setShowModal(false);
  };

  const openEditModal = (template) => {
    setCurrentTemplate(template);
    setShowEditModal(true);
  };
  const closeEditModal = () => {
    setCurrentTemplate(null);
    setShowEditModal(false);
  };

  const toggleDropdown = (templateId) => {
    setActiveDropdown((prevId) => (prevId === templateId ? null : templateId));
  };

  const onDuplicate = async (name, description, referenceId) => {
    try {
      const templateSkuuid = referenceId.split("#")[1];
      const newPromptTemplate = await createPromptTemplate(
        orgId,
        name,
        description,
        templateSkuuid
      );
      queryClient.setQueryData(
        ["promptTemplates", organizationskuuid],
        (oldData) => {
          return [...oldData, newPromptTemplate];
        }
      );
      NotificationService.notifySuccess(
        "Prompt template duplicated successfully"
      );
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to duplicate prompt template: ${errorMessage}`
      );
    }
  };

  const confirmDelete = async () => {
    try {
      await deletePromptTemplate(organizationskuuid, deletingSkuuid);
      NotificationService.notifySuccess("Prompt template deleted successfully");
      queryClient.setQueryData(
        ["promptTemplates", organizationskuuid],
        (oldData) => {
          return oldData.filter((data) => data.sk !== `CPT#${deletingSkuuid}`);
        }
      );
      setIsModalOpen(false);
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to delete prompt template: ${errorMessage}`
      );
    }
  };

  useEffect(() => {
    setOrgSkuuid(organizationskuuid);
  }, [organizationskuuid]);

  const onClickEditOption = (template) => {
    openEditModal(template);
    hideDropdown();
  };

  const onClickDuplicateOption = (template) => {
    onDuplicate(template.title, template.prompt_template_content, template.sk);
    hideDropdown();
  };

  const onClickDeleteOption = (template) => {
    const templateSkuuid = template.sk.split("#")[1];
    setDeletingSkuuid(templateSkuuid);
    setIsModalOpen(true);
    hideDropdown();
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col self-stretch px-6 pb-3 bg-white rounded-md border-b-gray-100 max-md:px-5">
       
      </div>
      <div>
        <div className="flex flex-row p-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <div
              onClick={openModal}
              className="flex flex-col justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full cursor-pointer"
            >
              <img
                loading="lazy"
                src={ADD_BLUE_ICON_URL}
                className="object-contain self-center w-12 aspect-square"
                alt="Create prompt template"
              />
              <div className="mt-5 w-full text-center font-bold text-[#1DA1F2]">
                Create Prompt Template
              </div>
              <div className="mt-5 w-full text-center">
                Create a new prompt for <br /> customized search
              </div>
            </div>
            {isPromptTemplateLoading ? (
              <LoadingCard />
            ) : (
              promptTemplates.map((userTemplate) => (
                <PromptTemplateCard
                  key={userTemplate.id}
                  selectedPromptId={activeDropdown}
                  template={userTemplate}
                  onClickOptions={toggleDropdown}
                  onClickEditOption={onClickEditOption}
                  onClickDuplicateOption={onClickDuplicateOption}
                  onClickDeleteOption={onClickDeleteOption}
                />
              ))
            )}
          </div>
          <NewTemplate
              showModal={showModal}
              organizationId={orgId}
              organizationskuuid={organizationskuuid}
              queryClient={queryClient}
              closeModal={closeModal}
            />
            {currentTemplate && (
              <EditTemplate
                name={currentTemplate.title}
                queryClient={queryClient}
                organizationskuuid={organizationskuuid}
                description={currentTemplate.prompt_template_content}
                templateSk={currentTemplate.sk}
                reference={currentTemplate.prompt_template_reference}
                closeModal={closeEditModal}
                showModal={showEditModal}
              />
            )}
        </div>
      </div>
      <DeleteConfirmationModal
        showModal={isModalOpen}
        setOpenModal={setIsModalOpen}
        confirmDelete={confirmDelete}
        entityName="Prompt template"
      />
    </div>
  );
}

export default PromptTemplate;
