import React from "react";
import { NavLink } from "react-router-dom";


function ItemList({ items = [], styles }) {


  return (
    <ul className="mt-2 list-none text-gray-700 flex pl-4 flex-col overflow-y-auto no-scrollbar">
      {items.map((item) => (
        <li key={`item-link-${item.label}`}> 
          <NavLink
            to={item.url}
            className={(navData) =>
              `self-stretch h-12 px-4 justify-start items-center gap-6 inline-flex w-full ${styles(navData)}`
            }
          >
            {item.icon}
            <div className="grow shrink basis-0 self-stretch py-3 flex-col justify-start items-start gap-0.5 inline-flex">
              <div className="self-stretch text-sm font-normal leading-normal tracking-wide">
                {item.label}
              </div>
            </div>
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

export default ItemList;
