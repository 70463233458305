import UnauthorizedScreen from "./Unauthorized";

const ErrorFallback = ({ error }) => {
  if (error.message === "UnauthorizedError") {
    return <UnauthorizedScreen />;
  }
  return <div>There was an unexpected error: {error.message}</div>;
};

export default ErrorFallback;
