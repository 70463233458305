import CollaboratorsForm from "../collaborators/CollaboratorsForm";
import BaseModal from "../common/modals/BaseModal";

export default function AddCollaboratorModal({
  currentCollaboratorEmails,
  openModal,
  handleCancel,
  collaborators,
  setCollaborators,
  createCollaboratorHandler,
  setIsValidCollaboratorsData,
  isValidCollaboratorsData,
}) {
  return (
    <BaseModal
      headerMessage="Add collaborator to the project"
      showModal={openModal}
      isDismissible={true}
      size="xl"
      handleClose={handleCancel}
      mainClassName="rounded-2xl"
      saveLabel="Create"
      saveDisabled={!isValidCollaboratorsData || collaborators.length === 0}
      handleSave={createCollaboratorHandler}
    >
      <div className="space-y-4 h-[240px]">
        <CollaboratorsForm
          currentCollaboratorEmails={currentCollaboratorEmails}
          titleIsVisible={false}
          collaborators={collaborators}
          setCollaborators={setCollaborators}
          setIsValidCollaboratorsData={setIsValidCollaboratorsData}
        />
      </div>
    </BaseModal>
  );
}
