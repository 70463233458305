import React, { useState } from "react";
import classNames from "classnames";
import { FaSave } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { LuRefreshCcw } from "react-icons/lu";
// import { FaMagic } from "react-icons/fa";

export function ActionControlsBuilder(
  cancelCallback,
  saveCallback,
  processCallback,
  enhanceCallback,
  isLoading
) {
  const ActionControls = ({ store }) => {
    const [isSaving, setIsSaving] = useState(false);

    const handleSave = async () => {
      if (isSaving) return;
      setIsSaving(true);
      try {
        await saveCallback();
      } finally {
        setIsSaving(false);
      }
    };

    const handleProcess = async () => {
      if (isLoading) return;
      try {
        await processCallback();
      } finally {
        setIsSaving(false);
      }
    };

    const sizeClasses = {
      small: "min-h-[30px] min-w-[60px] px-2 py-1 text-xs rounded-full",
    };

    const commonBtnClass =
      "flex items-center justify-center transition duration-300 ease-in-out shadow-sm hover:shadow-md transform hover:scale-105";

    const saveBtnClass = classNames(
      "text-white bg-blue-500 hover:bg-blue-600",
      commonBtnClass,
      sizeClasses["small"],
      {
        "cursor-not-allowed bg-gray-400": isSaving,
      }
    );

    const actionBtnClass = classNames(
      "text-white bg-teal-500 hover:bg-teal-600",
      commonBtnClass,
      sizeClasses["small"],
      {
        "cursor-not-allowed bg-gray-400": isLoading,
      }
    );

    const cancelBtnClass = classNames(
      "text-red-600 bg-white border border-red-300 hover:bg-red-50",
      commonBtnClass,
      sizeClasses["small"]
    );

    return (
      <div className="flex flex-row space-x-2">
        <button onClick={cancelCallback} className={cancelBtnClass}>
          <MdCancel className="text-base" />
        </button>
        {/* 
        This button is disabled since this functionality will not be used anymore, but it is commented and not removed since it may be required again in the future.
        
        <button
          onClick={enhanceCallback}
          className={actionBtnClass}
          disabled={isSaving || isLoading}
        >
          <FaMagic className="text-base" />
        </button> */}

        <button
          onClick={handleProcess}
          className={actionBtnClass}
          disabled={isSaving || isLoading}
        >
          <LuRefreshCcw className="text-base" />
        </button>

        <button
          onClick={handleSave}
          className={saveBtnClass}
          disabled={isSaving || isLoading}
        >
          {isSaving ? (
            <div className="flex items-center space-x-1">
              <span className="loader border-t-2 border-white rounded-full w-4 h-4 animate-spin"></span>
              <span>Loading...</span>
            </div>
          ) : (
            <FaSave className="text-base" />
          )}
        </button>

      </div>
    );
  };

  return ActionControls;
}
