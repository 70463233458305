import React, {useState} from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { FaTrashAlt } from "react-icons/fa";
import StatusType from "../../../data/enums/statusType";
import { extractPkSk } from "../../../utils/common";
import { postStatusStyles } from "../../../config/styles";
import EditableSpan from "../../common/forms/EditableSpan";
import { updatePost } from "../../../services";
import * as NotificationService from "../../../utils/notificationService";


export default function CampaignPostsDisplayer({
  posts,
  selectedPost,
  setSelectedPostSkuuid,
  deletePostHandler,
}) {
  const queryClient = useQueryClient();
  const { campaignskuuid, organizationskuuid } = useParams();
  const [isInput, setIsInput] = useState(false);

  const handleOnChanePostId = (post) => {
    if (!post) return;

    if(post.status === StatusType.PROCESSING) return;
    
    const { skuuid: postSkuuid } = extractPkSk(post.id);
    setSelectedPostSkuuid(postSkuuid);
  };

  const getStatusBackground = (key, status) => {
    return postStatusStyles[key]?.[status] ?? postStatusStyles[key][StatusType.READY];
  };

  const onUpdatePostTitle = async (post, newTitle) => {
    const payload = { title: newTitle };
    const updatedPost = await updatePost(post.id, payload, organizationskuuid);
    const currentPostTitle = updatedPost.title;
    NotificationService.notifySuccess(
      "Post title updated successfully"
    );
    queryClient.setQueryData(
      [`campaign-${campaignskuuid}-posts`],
      (oldData) => {
        if (!oldData) return [];
        return oldData.map((p) =>
          p.id === post.id ? { ...p, title: currentPostTitle } : p
        );
      }
    );
    return currentPostTitle;
  };

  return (
    <ul className="flex flex-col h-[calc(100vh-250px)] max-lg:h-[calc(100vh-175px)] overflow-y-auto">
      {posts.map((post) => {
        const postStatus = post.status;
        const isProcessing = postStatus === StatusType.PROCESSING;
        const hasFailed = postStatus === StatusType.FAILED;
        return (
          <a
            key={post.id}
            onClick={() => handleOnChanePostId(post)}
            className={`${isProcessing && "pointer-events-none"}`}
          >
            <li
              className={`flex flex-row justify-between px-3 py-4 rounded-md mt-4 relative ${
                selectedPost?.id === post.id
                  ? isInput ? "bg-[#4AB3F4] text-[#121212]" : getStatusBackground("selected", postStatus)
                  : getStatusBackground("container", postStatus)
              }`}
            >

              <div className="w-[90%]">
                <EditableSpan
                  initialContent={post.title}
                  charactesLimit={25}
                  setIsInput={setIsInput}
                  onSave={(value) => onUpdatePostTitle(post, value)}
                  validateInput={(value) => value.trim().length > 0}
                />
              </div>

              {isProcessing || hasFailed ? (
                <div
                  className={`h-5 w-[60px] ${getStatusBackground(
                    "textContainer",
                    postStatus
                  )} text-center`}
                >
                  <span className="text-[10px]">
                    {StatusType.toReadable(postStatus)}
                  </span>
                </div>
              ) : (
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    deletePostHandler(post);
                  }}
                >
                  <FaTrashAlt className={`h-4 w-3 ${selectedPost?.id !== post.id && "text-[#EF4444]"}`}/>
                </div>
              )}
            </li>
          </a>
        );
      })}
    </ul>
  );
}
