import CampaignType from "../data/enums/campaignType"
import { ReactComponent as PDFIcon } from "../assets/pdf_icon.svg";
import { ReactComponent as DocxIcon } from "../assets/docx_icon.svg";
import { ReactComponent as TxtIcon } from "../assets/txt_icon.svg";
import { ReactComponent as CsvIcon } from "../assets/csv_icon.svg";
import { ReactComponent as XlsxIcon } from "../assets/xlsx_icon.svg";


// ENV
export const READABLE_SITE_NAME = 'Campaigns Planet'
export const SITE_EMAIL = 'info@campaignsplanet.com'

export const POLOTNO_KEY = process.env.REACT_APP_POLOTNO_KEY

export const API_SERVER =  process.env.REACT_APP_API_SERVER

export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID
export const COGNITO_USER_POOL_APP_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_APP_CLIENT_ID


export const APPS_LINKEDIN_OAUTH_URL = "https://www.linkedin.com/oauth/v2/authorization"
export const APPS_LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID

export const APPS_FACEBOOK_OAUTH_URL = "https://www.facebook.com/dialog/oauth"
export const APPS_FACEBOOK_CLIENT_ID = process.env.REACT_APP_APPS_FACEBOOK_CLIENT_ID
export const APPS_FACEBOOK_CONFIG_ID = process.env.REACT_APP_APPS_FACEBOOK_CONFIG_ID

export const APPS_INSTAGRAM_OAUTH_URL = "https://www.instagram.com/oauth/authorize"
export const APPS_INSTAGRAM_CLIENT_ID = process.env.REACT_APP_APPS_INSTAGRAM_CLIENT_ID
export const APPS_INSTAGRAM_CONFIG_ID = '581976657434110'

export const APPS_X_OAUTH_URL = "https://x.com/i/oauth2/authorize"
export const APPS_X_CLIENT_ID = process.env.REACT_APP_APPS_X_CLIENT_ID

// Campaigns
const MANUAL = CampaignType.MANUAL;
const AUTOMATIC = CampaignType.AUTOMATIC;

export const campaignOptions = [
  { id: MANUAL, value: MANUAL, label: CampaignType.toReadable(MANUAL) },
  { id: AUTOMATIC, value: AUTOMATIC, label: CampaignType.toReadable(AUTOMATIC) },
];

export const initialExpandedSections = {
  warning: false,
  error: false,
  sucess: false,
};

export const initalCampaign = {
  title: "",
  type: CampaignType.MANUAL,
};

export const initalOrganization = {
  name: "",
};

export const defaultCollaborator = {
  name: "",
  email: "",
  role: "",
};

export const initialErrors = { name: "" };

// File
export const MAX_SIZE_PER_FILE = 10 * 1024 ** 2;

export const MAX_FILES = 15;

export const validFileTypes = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/msword",
  "text/plain",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const ICON_STYLE = "w-7 h-7";

export const FILE_ICONS = {
  ".docx": <DocxIcon className={ICON_STYLE} />,
  ".pdf": <PDFIcon className={ICON_STYLE} />,
  ".doc": <DocxIcon className={ICON_STYLE} />,
  ".txt": <TxtIcon className={ICON_STYLE} />,
  ".csv": <CsvIcon className={ICON_STYLE} />,
  ".xlsx": <XlsxIcon className={ICON_STYLE} />,
};
