import React from "react";
import { useNavigate } from "react-router-dom";
import { FaCoins } from "react-icons/fa";

import { getTotalCredits, formatCredits } from "../../utils/formaters";

export default function CreditsCounter({ organizationSkuuid, orgData }) {
    const navigate = useNavigate();
    const organizationCredits = getTotalCredits(orgData);
    const organizationCreditsFormatted = formatCredits(organizationCredits);

    const textCreditColor = organizationCredits <= 0
        ? "text-red-500"
        : organizationCredits < 100
            ? "text-[#8F4225]"
            : "text-gray-900";

    const bgCreditColor = organizationCredits <= 0
        ? "bg-red-100"
        : organizationCredits < 100
            ? "bg-[#FFCBA3]"
            : "bg-white";


    return (
        <div
            className={`${bgCreditColor} rounded-lg shadow-sm px-4 py-2 hover:shadow-md transition-shadow flex items-center gap-2 min-w-[180px] h-12 cursor-pointer`}
            onClick={() => {
                navigate(
                    `/mkt/organizations/${organizationSkuuid}/settings/billing`
                );
            }}
        >
            <FaCoins className={`text-lg ${textCreditColor}`} />
            <div className="flex items-baseline gap-1.5">
                <span className={`text-sm font-medium ${textCreditColor}`}>
                    {" "}
                    Available Credits :
                </span>
                <span className={`text-sm font-semibold ${textCreditColor}`}>
                    {organizationCreditsFormatted}
                </span>
            </div>
        </div>
    );
}
