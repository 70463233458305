import React, { useState } from "react";
import FileUploader from "./FileUploader";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { createKnowledgeBase } from "../../services";
import * as NotificationService from "../../utils/notificationService";

import {
  useCreateFile,
  useUploadFile,
  useGetPresignedUrl,
} from "../../hooks/File";

export default function CreateKnowledgeBase() {
  const { organizationskuuid } = useParams();
  const { orgId } = useOutletContext();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleFilesChange = (files) => {
    setSelectedFiles(files);
  };

  const createFile = useCreateFile(organizationskuuid );
  const uploadFile = useUploadFile();
  const getPresignedUrl = useGetPresignedUrl();

  const onSave = async () => {
    if (selectedFiles.length === 0) return null;
    try {
      const { sk: knowledgeBaseSk } = await createKnowledgeBase(
        organizationskuuid,
        {
          title,
          organization_id: orgId,
        }
      );

      setIsLoading(true);
      const uploadPromises = selectedFiles.map(async (file) => {
        try {
          const payload = {
            file_name: file.name,
            organization_skuuid: organizationskuuid,
          };

          const { presigned_url, file_s3_uri, file_id } =
            await getPresignedUrl.mutateAsync(payload);

          await uploadFile.mutateAsync({ file, presigned_url });
          const filePayload = {
            id: file_id,
            content: file_s3_uri,
            file_name: file.name,
            file_size: file.size,
            status: "UPLOADING",
            origin_sk: knowledgeBaseSk,
          };

          return createFile.mutate(filePayload);
        } catch (error) {
          NotificationService.notifyError(
            `An error occurred while uploading the file: ${file.name}`
          );
          return null;
        }
      });

      (await Promise.all(uploadPromises)).filter(Boolean);

      setSelectedFiles([]);
      setTitle("");
      NotificationService.notifySuccess("Knowledge base created successfully.");
      navigate(
        `/mkt/organizations/${organizationskuuid}/resources/knowledge-bases`
      );
    } catch (error) {
      NotificationService.notifyError(
        "An error occurred while creating the knowledge base."
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    navigate(
      `/mkt/organizations/${organizationskuuid}/resources/knowledge-bases`
    );
  };

  return (
    <div className="flex flex-col items-center space-y-6 w-full ">
      <div
        className={`border-2 border-dashed rounded-lg w-full p-4 text-center bg-[#F5F7FA] transition-all ${
          !title ? "border-gray-300" : "border-blue-500"
        }`}
      >
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter the title"
          className="w-full bg-transparent text-gray-900 font-bold text-xl leading-8 tracking-wide text-center outline-none"
        />
      </div>

      <FileUploader
        onFilesChange={handleFilesChange}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />
      <ActionButtonsGroup
        saveLabel="Save"
        onSave={onSave}
        saveDisabled={!title || !selectedFiles.length || isLoading}
        cancelLabel="Cancel"
        onCancel={onCancel}
        cancelDisabled={false}
      />
    </div>
  );
}
