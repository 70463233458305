import React from 'react'


function TabList({items = [], onClick}) {
  return (
    <div className="flex relative gap-6 border-b border-solid text-base font-semibold tracking-tight text-center">
      {items.map((item) => (
        <div
          key={`item-${item.id}`}
          className={
            item.isActive
              ? "text-main-blue border-b-[3px] border-main-blue"
              : "hover:text-blue-500"
          }
          onClick={() => onClick(item.id)}
        >
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
}

export default TabList;
