class SocialNetworkType {
  static X = "X";
  static LINKEDIN = "LinkedIn";
  static FACEBOOK = "FACEBOOK";
  static INSTAGRAM = "INSTAGRAM";

  static toReadable(type) {
    switch (type) {
      case this.X:
        return 'X';
      case this.LINKEDIN:
        return "LinkedIn";
      case this.FACEBOOK:
        return "Facebook";
      case this.INSTAGRAM:
        return "Instagram";
      default:
        return null;
    }
  }
  static getNetworkTypeOptions() {
    return [
      this.X,
      this.LINKEDIN,
      this.INSTAGRAM,
      this.FACEBOOK,
    ];
  }
}

export default SocialNetworkType;