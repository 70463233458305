import React, { useState, useCallback } from "react";
import * as NotificationService from "../../utils/notificationService";

const CaptionContainer = ({
  postCaption,
  isEditing,
  setPostCaption,
  setShowMoreText,
  showMoreText,
  setIsEditing,
  renderState,
  saveEdition,
  originalCaption,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(async () => {
    if (!postCaption.trim()) {
      NotificationService.notifyWarning("Caption cannot be empty.");
      return;
    }

    setLoading(true);
    try {
      await saveEdition();
      setIsEditing(false);
      NotificationService.notifySuccess("Caption saved successfully.");
    } catch (err) {
      NotificationService.notifyError("Failed to save caption.");
    } finally {
      setLoading(false);
    }
  }, [postCaption, saveEdition, setIsEditing]);

  const handleCancelAction = useCallback(() => {
    setPostCaption(originalCaption || "");
    setIsEditing(false);
  }, [originalCaption, setPostCaption, setIsEditing]);

  return (
    <div>
      {postCaption || isEditing ? (
        <div className="py-10 px-6 space-y-4 bg-white shadow-sm rounded-lg">
          <div>
            {isEditing ? (
              <textarea
                autoFocus
                rows="6"
                value={postCaption}
                onChange={(e) => setPostCaption(e.target.value)}
                className="w-full p-3 text-gray-800 bg-transparent resize-none focus:outline-none border border-gray-300 rounded-lg shadow-sm focus:border-blue-400 focus:ring focus:ring-blue-200 transition"
                style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
                aria-label="Edit caption"
                aria-live="polite"
              />
            ) : (
              <p className="whitespace-pre-wrap leading-relaxed text-gray-800 font-medium">
                {showMoreText
                  ? postCaption
                  : postCaption.substring(0, 350).trim()}
              </p>
            )}
          </div>

          <div className="flex justify-center space-x-4">
            {isEditing ? (
              <>
                <button
                  onClick={handleCancelAction}
                  className="text-blue-600 hover:text-blue-800 transition font-medium"
                  aria-label="Cancel editing"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className={`text-blue-600 hover:text-blue-800 transition font-medium ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  aria-label="Save caption"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              </>
            ) : (
              <>
                {postCaption.length >= 350 && (
                  <button
                    onClick={() => setShowMoreText(!showMoreText)}
                    className="text-blue-600 hover:text-blue-800 transition font-medium"
                    aria-label="Toggle show more text"
                  >
                    {showMoreText ? "Show Less" : "Show More"}
                  </button>
                )}
                <button
                  onClick={() => setIsEditing(true)}
                  className="text-blue-600 hover:text-blue-800 transition font-medium"
                  aria-label="Edit caption"
                >
                  Edit Caption
                </button>
              </>
            )}
          </div>

          <div className="inline-flex items-center justify-center font-medium">
            {renderState()}
          </div>
        </div>
      ) : (
        <div className="flex flex-col p-6 space-y-3">
          <div className="h-3 bg-gray-300 rounded"></div>
          <div className="h-3 bg-gray-300 rounded"></div>
          <div className="h-3 w-1/2 bg-gray-300 rounded"></div>
        </div>
      )}
    </div>
  );
};

export default CaptionContainer;
