import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@blueprintjs/core";
import { ImagesGrid } from "polotno/side-panel";
import { getImageSize } from "polotno/utils/image";
import {
  useDeleteImages,
  useGetImages,
  useSaveImages,
} from "../../hooks/CustomImages/useCustomImages";

export const UploadPanel = observer(({ store, organizationDetails }) => {
  const [images, setImages] = useState([]);
  const [isUploading, setUploading] = useState(false);

  const { mutate: saveImage } = useSaveImages(
    organizationDetails.organizationId,
    organizationDetails.campaignId,
    organizationDetails.postId
  );

  const { mutate: deleteImage } = useDeleteImages(
    organizationDetails.organizationId
  );

  const {
    data: customImages = [],
    isLoading,
    refetch: refetchImages,
  } = useGetImages(organizationDetails.organizationId);

  useEffect(() => {
    if (!isLoading) {
      setImages(customImages);
    }
  }, [customImages, isLoading]);

  const handleFileInput = async (e) => {
    const { target } = e;
    setUploading(true);
    const uploadPromises = [];

    for (const file of target.files) {
      uploadPromises.push(saveImage(file));
    }

    await Promise.all(uploadPromises);

    setTimeout(async () => {
      const { data: customImages = [] } = await refetchImages();

      setImages(customImages);
      setUploading(false);
      target.value = null;
    }, 2000);
  };

  const handleDeleteImage = async (event, id) => {
    event.stopPropagation();
    setUploading(true);
    await deleteImage(id.split("#")[1]);

    setTimeout(async () => {
      const { data: customImages = [] } = await refetchImages();
      setImages(customImages);
      setUploading(false);
    }, 2000);
  };

  const handleImageSelect = async (image, pos, element) => {
    const { url, id } = image;
    let { width, height } = await getImageSize(url);
    const isSVG = url.includes("svg+xml") || url.includes(".svg");
  
    const type = isSVG ? "svg" : "image";
  
    if (element && element.type === "svg" && !element.locked && type === "image") {
      element.set({ maskSrc: url });
      return;
    }
  
    if (element && element.type === "image" && !element.locked && type === "image") {
      element.set({ src: url });
      return;
    }
  
    const scale = Math.min(store.width / width, store.height / height, 1);
    width *= scale;
    height *= scale;
  
    const x = (pos?.x || store.width / 2) - width / 2;
    const y = (pos?.y || store.height / 2) - height / 2;
  
    store.activePage?.addElement({
      type,
      src: url,
      x,
      y,
      width,
      height,
      id,
    });
  };
  
  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center justify-center p-4">
        <label htmlFor="input-file">
          <Button
            icon="upload"
            onClick={() => {
              document.querySelector("#input-file")?.click();
            }}
            loading={isUploading}
            className="w-full"
          >
            {`Upload image to ${organizationDetails.organizationName}`}
          </Button>
          <input
            type="file"
            id="input-file"
            className="hidden"
            onChange={handleFileInput}
            multiple
            accept="image/*"
          />
        </label>
      </div>
      <ImagesGrid
        images={images}
        getPreview={(image) => image.url}
        crossOrigin="anonymous"
        getCredit={(image) => (
          <div>
            <Button
              icon="trash"
              onClick={async (e) => handleDeleteImage(e, image.id)}
              className="absolute bottom-2 right-2"
            ></Button>
          </div>
        )}
        onSelect={handleImageSelect}
      />
    </div>
  );
});