import React, { useEffect, useState } from "react";

import { getCurrentDate } from "../utils/common";
import BaseModal from "./common/modals/BaseModal";

export default function SchedulePostModal({
  openModal,
  scheduleTargetPlatformData,
  handleDateTimeChange,
  schedulePostHandler,
  handleCancel,
}) {
  const [isTimeValid, setIsTimeValid] = useState(true);

  const disableSchedule = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 6);
    const minTime = now.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return (
      scheduleTargetPlatformData.start_date === getCurrentDate() &&
      scheduleTargetPlatformData.start_time < minTime
    );
  };



  useEffect(() => {
    setIsTimeValid(!disableSchedule());
  }, [
    scheduleTargetPlatformData.start_time,
    scheduleTargetPlatformData.start_date,
  ]);

  return (
    <BaseModal
      showModal={openModal}
      size="2xl"
      handleClose={handleCancel}
      headerMessage={`Schedule Post`}
      saveLabel="Schedule Post"
      handleSave={schedulePostHandler}
      handleCancel={handleCancel}
      isDismissible={true}
    >
      <div className="space-y-6">
        <div className="relative">
          <input
            type="date"
            value={scheduleTargetPlatformData.start_date}
            onChange={handleDateTimeChange}
            min={getCurrentDate()}
            id="campaign_start_date"
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
            placeholder=" "
          />
          <label
            htmlFor="campaign_start_date"
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Start Date
          </label>
        </div>
        <div className="relative">
          <input
            type="time"
            value={scheduleTargetPlatformData.start_time}
            onChange={handleDateTimeChange}
            id="campaign_start_time"
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
            placeholder=" "
          />
          <label
            htmlFor="campaign_start_date"
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Start Time
          </label>
          {!isTimeValid && (
            <div className="text-red-500 text-sm">
              Please select a time at least 5 minutes in the future.
            </div>
          )}
        </div>
      </div>
    </BaseModal>
  );
}
