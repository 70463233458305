import React from "react";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

import { extractPkSk } from "../../utils/common";
import { truncateContent } from "../../utils/common";
import { ReactComponent as ThreeDot } from "./../../assets/threedot.svg";
import PopUpMenuItems from '../common/PopUpMenuItems';
import LocalStorageKeys from "../../data/enums/localStorageKeys";
import useLocalStorage from "../../hooks/LocalStorage/useLocalStorage";

const ProjectCard = ({
    project,
    projectIdSelected,
    organizationSkuuid,
    onClickOptions,
    onClickEditOption,
    onClickDeleteOption,
}) => {
    const navigate = useNavigate();
    const { setActiveProjectId } = useOutletContext();
    const [,setProjectIdValue] = useLocalStorage(LocalStorageKeys.PROJECT_ID_KEY, null);

    const { truncatedText, isTruncated } = truncateContent(project.title);
    const menuItems = [
        { label: "Edit", onClick: () => onClickEditOption(project), isActive: true },
        { label: "Delete", onClick: () => onClickDeleteOption(project), isActive: true }
    ];

    return (
        <div
            key={project.id}
            className="relative flex flex-col justify-center bg-white rounded min-h-[220px] shadow-lg w-full p-4 cursor-pointer"
            onClick={() => {
                const { skuuid } = extractPkSk(project.id);
                setProjectIdValue(project.id);
                setActiveProjectId(project.id);
                navigate(`/mkt/organizations/${organizationSkuuid}/projects/${skuuid}/campaigns`);
            }}
        >
            <div
                className="text-lg text-center font-bold text-[#1DA1F2] overflow-hidden truncate"
                title={isTruncated ? project.title : null}
            >
                {truncatedText}
            </div>
            <div
                className="absolute top-3 right-3 cursor-pointer px-2"
                onClick={(e) => {
                    e.stopPropagation();
                    onClickOptions(project.id)
                }}
            >
                <ThreeDot />
            </div>
            {projectIdSelected === project.id && (
                <PopUpMenuItems
                    items={menuItems}
                />
            )}
        </div>
    );
};

export default ProjectCard;
