import React, { useContext, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { initalOrganization, initialErrors } from "../../config/constants";
import { isInputValid, validateOrgNameInput } from "../../utils/validations";
import * as NotificationService from "../../utils/notificationService";
import { createOrganization, createRole } from "../../services";
import CollaboratorsForm from "../collaborators/CollaboratorsForm";
import CustomInput from "../common/forms/CustomInput";
import { AuthContext } from "../../AuthProvider";
import { useNavigate } from "react-router-dom";
import BaseModal from "../common/modals/BaseModal";

export default function CreateOrganizationModal({
  openModal,
  setOpenModal,
  setOrgSelected,
  setOrganizationId,
}) {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState(initialErrors);
  const [isValidCollaboratorsData, setIsValidCollaboratorsData] =
    useState(true);
  const [newOrganization, setNewOrganization] = useState(initalOrganization);
  const [collaborators, setCollaborators] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const auth = useContext(AuthContext);

  const handleInputChange = (field, errorMessage) => (e) => {
    const value = e.target.value;
    setNewOrganization((prevState) => ({ ...prevState, [field]: value }));
    validateOrgNameInput(value, field, setErrors, errorMessage);
  };

  const canSaveData = () => {
    const isNameValid = isInputValid(newOrganization.name);
    return isNameValid && isValidCollaboratorsData;
  };

  const navigate = useNavigate();

  const createNewOrganization = async () => {
    try {
      const organizationCreated = await createOrganization({
        ...newOrganization,
        owner: `${auth.user.given_name} ${auth.user.family_name}`,
      });

      NotificationService.notifySuccess("Organization created successfully");
      if (collaborators.length !== 0) {
        await createCollaborators(organizationCreated.id);
      }

      queryClient.setQueryData(["myOrganizations"], (oldData) => {
        if (!oldData) return [organizationCreated];
        return [...oldData, organizationCreated];
      });
      const orguuid = organizationCreated.sk.split("#")[1];
      setOrgSelected(organizationCreated);
      setOrganizationId(organizationCreated.id);
      navigate(`/mkt/organizations/${orguuid}/projects`);
    } catch (error) {
      NotificationService.notifyError(
        `Failed to create Organization: ${error.message}`
      );
    } finally {
      handleCancel();
    }
  };

  const createCollaborators = async (orgId) => {
    try {
      const payloads = collaborators.map((collaborator) => ({
        role: collaborator.role,
        assigned_to: collaborator.email,
        entity_id: orgId,
        name: collaborator.name,
      }));
      await Promise.all(payloads.map(createRole));
      NotificationService.notifySuccess("Collaborators added successfully");
    } catch (error) {
      NotificationService.notifyError(
        `Failed to add collaborators to the Organization: ${error.message}`
      );
    } finally {
      handleCancel();
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setNewOrganization(initalOrganization);
    setErrors(initialErrors);
    setCollaborators([]);
  };

  useEffect(() => {
    setSaveDisabled(!canSaveData());
  }, [newOrganization, collaborators, isValidCollaboratorsData]);

  return (
    <BaseModal
      headerMessage="Create New Organization"
      showModal={openModal}
      isDismissible={true}
      size="xl"
      handleClose={handleCancel}
      mainClassName="rounded-2xl"
      saveLabel="Create"
      saveDisabled={saveDisabled}
      handleSave={createNewOrganization}
    >
      <div className="space-y-6">
        <div className="relative">
          <CustomInput
            autoFocus={true}
            id={`organization_name`}
            type="text"
            label="Name *"
            value={newOrganization.name}
            error={errors.name}
            onChange={handleInputChange("name", "")}
          />
        </div>
        <CollaboratorsForm
          currentCollaboratorEmails={[auth.user.email]}
          collaborators={collaborators}
          setCollaborators={setCollaborators}
          setIsValidCollaboratorsData={setIsValidCollaboratorsData}
        />
      </div>
    </BaseModal>
  );
}
