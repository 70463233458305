import React, { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import * as NotificationService from "../../utils/notificationService";
import { validateInput } from "../../utils/validations";
import { updateProject } from "../../services";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";
import { extractPkSk } from "../../utils/common";
import BaseModal from "../common/modals/BaseModal";

const EditProjectModal = ({
  organizationskuuid,
  project,
  showModal,
  closeModal,
}) => {
  const queryClient = useQueryClient();
  const [projectTitle, setProjectTitle] = useState(project.title || "");
  const initialErrors = { title: "" };
  const [errors, setErrors] = useState(initialErrors);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  useEffect(() => {
    const isSameData = project.title === projectTitle;
    const isDataEmpty = !projectTitle.trim();
    setIsSaveButtonDisabled(isDataEmpty || isSameData);
  }, [projectTitle]);

  const handleSave = async () => {
    try {
      const { skuuid: projectSkuuid } = extractPkSk(project.id);
      const payload = { title: projectTitle };
      const updatedProject = await updateProject(organizationskuuid, projectSkuuid, payload);
      NotificationService.notifySuccess("Project updated successfully");
      queryClient.setQueryData(
        ["myProjects", organizationskuuid],
        (oldData) => {
          if (!oldData) return [];
          return oldData.map((project) =>
            project.id === updatedProject.id
              ? { ...project, ...updatedProject }
              : project
          );
        }
      );
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(errorMessage);
    } finally {
      closeModal();
    }
  };

  const handleInputChange = (field, errorMessage) => (e) => {
    const value = e.target.value;
    setProjectTitle(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  const handleCancel = () => {
    closeModal();
    setProjectTitle("");
    setErrors(initialErrors);
  };

  const validateProjectTitle = () => {
    validateInput(
      projectTitle,
      "title",
      setErrors,
      "Project title is required"
    );
  };

  const validateAllInputs = () => {
    validateProjectTitle();
  };

  return (
    <BaseModal
      showModal={showModal}
      size="lg"
      handleClose={handleCancel}
      headerMessage="Update Project"
      saveLabel="Update"
      saveDisabled={isSaveButtonDisabled}
      handleSave={handleSave}
      handleSaveDisabled={validateAllInputs}
      isDismissible={true}
    >
      <div className="space-y-6">
        <div className="relative">
          <label className="block text-black">Project title *</label>
          <textarea
            autoFocus
            className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
            placeholder="Project title*"
            value={projectTitle}
            onChange={handleInputChange(
              "title",
              "Project title is required"
            )}
          />
          <InputErrorDisplayer message={errors.title} />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditProjectModal;
