import { useState, useEffect, useMemo } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { fetchAuthSession } from "aws-amplify/auth"

import Loading from "./common/Loading"
import { API_SERVER } from "../config/constants"
import { callbackURL, extractPkSk } from "../utils/common"
import LocalStorageKeys from "../data/enums/localStorageKeys";
import useLocalStorage from "../hooks/LocalStorage/useLocalStorage";
import * as NotificationService from "../utils/notificationService";
import { formatErrorMesage } from "../utils/formaters"

function useURLQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function FacebookCallback() {
  let urlQueryParams = useURLQueryParams()
  const [isLoading, setIsLoading] = useState(false)
  const [organizationId] = useLocalStorage(LocalStorageKeys.ORGANIZATION_ID_KEY, null);
  const { skuuid: organizationskuuid } = extractPkSk(organizationId);
  const [returnQueryParams, setReturnQueryParams] = useState()
  const [redirectToFallback, setRedirectToFallback] = useState(false);


  useEffect(() => {
    setIsLoading(true)
    fetchAuthSession().then((session) => {
      let metadata = urlQueryParams.get('state');
      let error = urlQueryParams.get("error");
      let parsedMetadata = {};

      try {
        parsedMetadata = JSON.parse(atob(metadata));
      } catch (error) {
        console.error("Failed to decode metadata", error);
      }

      if (error) {
        error = formatErrorMesage(error)
        NotificationService.notifyError("Failed to connect: " + error);
        setIsLoading(false);
        setRedirectToFallback(true);
        return;
      }

      const accountName = parsedMetadata.account_name || 'Default account name';
      const body = JSON.stringify({
        'code': urlQueryParams.get('code'),
        'callback_url': callbackURL('Facebook'),
        'meta': {
          'account_name': accountName,
        },
        'organization_id': organizationskuuid,
      })
      fetch(API_SERVER + `/organization/${organizationskuuid}/social-accounts/facebook/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`
        },
        body: body,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.message || "Network response was not ok");
          }
          setReturnQueryParams(new URLSearchParams({
            access_token: data.access_token,
            social_account: "FACEBOOK",
            account_name: accountName,
          }));
        })
        .catch((error) => {
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(`Failed to connect: ${errorMessage}`);
          setRedirectToFallback(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })
  }, [urlQueryParams])


  if (isLoading) {
    return <Loading />;
  }

  if (redirectToFallback) {
    const fallbackPath = `/mkt/organizations/${organizationskuuid}/resources/target-platforms`;
    return <Navigate to={fallbackPath} />;
  }

  if (returnQueryParams) {
    const returnPath = `/mkt/organizations/${organizationskuuid}/resources/target-platforms?${returnQueryParams.toString()}`;
    return <Navigate to={returnPath} />;
  }

  return null;
}

export default FacebookCallback
