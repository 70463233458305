import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Modal } from "flowbite-react";

import * as NotificationService from "../../utils/notificationService";
import Dropdown from "../common/forms/Dropdown";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import { extractPkSk } from "../../utils/common";
import {
    fetchFacebookPages,
    addFacebookPage,
} from "../../services";


const SelectFacebookPageModal = ({
    facebookAccessToken,
    facebookAccountName,
    showModal,
    setshowModal,
}) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { orgId } = useOutletContext();
    const { skuuid: orgSkuuid } = extractPkSk(orgId);
    const [selectedPage, setSelectedPage] = useState();

    const facebookPagesQuery = useQuery({
        queryKey: ["facebook-pages"],
        queryFn: async () => {
            return await fetchFacebookPages(orgSkuuid, { access_token: facebookAccessToken });
        },
        staleTime: 10 * 1000,
    });

    const handleSave = async () => {
        try {
            const payload = {
                page_access_token: selectedPage.access_token,
                account_name: facebookAccountName,
                organization_id: orgSkuuid,
            }
            await addFacebookPage(orgSkuuid, payload);
            NotificationService.notifySuccess("Social network connected successfully");
            queryClient.invalidateQueries(['user-social-accounts', orgSkuuid]);
        } catch (error) {
            const errorMessage = error.message || "An unexpected error occurred";
            NotificationService.notifyError(
                `Failed to add social account: ${errorMessage}`
            );
        } finally {
            setshowModal(false);
            navigate(
                `/mkt/organizations/${orgSkuuid}/resources/target-platforms`
            );
        }
    };

    const handleCancel = () => {
        setshowModal(false);
        NotificationService.notifyError(
            `Add social account process canceled.`
        );
        navigate(
            `/mkt/organizations/${orgSkuuid}/resources/target-platforms`
        );
    };

    return (
        <Modal show={showModal} size="lg" onClose={handleCancel}>
            <Modal.Header className="text-center">Select Facebook Page</Modal.Header>
            <Modal.Body className="p-3 py-6">
                <div className="space-y-6 pb-6">
                    <Dropdown
                        selectedOption={selectedPage}
                        options={facebookPagesQuery.data}
                        onSelect={setSelectedPage}
                        labelExtractor={(page) => page.name}
                        defaultTitle="Select Facebook page."
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ActionButtonsGroup
                    saveLabel="Select"
                    saveDisabled={!selectedPage}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default SelectFacebookPageModal;