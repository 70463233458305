import { useEffect, useState, useRef, useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { AuthContext } from "../../../AuthProvider";
import { ReactComponent as AgentAvatar } from "../../../assets/agent_avatar.svg";
import Button from "../../common/Button";
import AssistantMessageInput from "../AssistantMessageInput";
import SourcesList from "../../Campaigns/SourcesList";
import Typing from "../../Typing";
import PromptTemplatesDisplayer from "../PromptTemplates";
import {
  sendMessage,
  fetchMessageByRunId,
  sendMessageWithGroq,
} from "../../../services";
import AIModelType from "../../../data/enums/AI_model_type";
import * as NotificationService from "../../../utils/notificationService";
import { extractPkSk } from "../../../utils/common";
import useLocalStorage from "../../../hooks/LocalStorage/useLocalStorage";
import LocalStorageKeys from "../../../data/enums/localStorageKeys";
import { getTotalCredits } from "../../../utils/formaters";

export default function PostConversation({
  post,
  messages,
  projectPromptTemplates,
  messagesUpdatedCallback,
  organization,
  knowledgeBaseId,
}) {
  let auth = useContext(AuthContext);
  const messagesQuery = messages;
  const { pkuuid: postPkuuid, skuuid: postSkuuid } = extractPkSk(post.id);
  const elementRef = useRef();
  const queryClient = useQueryClient();

  const [localMessages, setLocalMessages] = useState({});
  const [isAssistantWaiting, setIsAssistantWaiting] = useState({});
  const [messageInputs, setMessageInputs] = useState({});
  const [isAssistantFailed, setIsAssistantFailed] = useState({});
  const [skipQuestions, setSkipQuestions] = useState(false);
  const [orgId] = useLocalStorage(LocalStorageKeys.ORGANIZATION_ID_KEY, null);
  const { pkuuid: ownerUserEmail, skuuid: organizationUuid} = extractPkSk(orgId);
  const organizationCredits = getTotalCredits(organization);
  const orgHasCredits = organizationCredits > 0;

  const promptTemplates = [
    ...(projectPromptTemplates || []),
  ];

  useEffect(() => {
    setLocalMessages((prev) => ({
      ...prev,
      [post.id]: [
        {
          type: "assistant",
          message_type: "text",
          message: `Hello, tell me what’s on your mind or pick up a suggested prompt templates`,
        },
        ...(messagesQuery.data || []),
      ],
    }));

    setMessageInputs((prev) => ({
      ...prev,
      [post.id]: prev[post.id] || "",
    }));

    messagesUpdatedCallback(messagesQuery.data);
    elementRef.current?.scrollIntoView();
  }, [auth.user.given_name, messagesQuery.data]);

  const handleInputChange = (event) => {
    const currentValue = event.target.value;
    setMessageInputs((prev) => ({
      ...prev,
      [post.id]: currentValue,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const handleModelMessage =
        aIModelMessageHandlerMap[AIModelType.getTypeFromString(post.ai_model)];
      handleModelMessage();
    }
  };

  const handleSendClick = () => {
    if (isAssistantWaiting[post.id] || !orgHasCredits) return;
    const event = new KeyboardEvent("keydown", {
      key: "Enter",
      code: "Enter",
    });
    handleKeyDown(event);
  };

  const handlePromptChange = (templateId) => {
    if (!orgHasCredits) return;
    const selectedItem = promptTemplates.find(
      (item) => item.sk === templateId
    );
    if (selectedItem) {
      setMessageInputs((prev) => ({
        ...prev,
        [post.id]: selectedItem.prompt_template_content,
      }));
    }
  };

  const interactWithAiAssistant = async (userMessage, sendMessage) => {
    const currentMessage = skipQuestions ? `${userMessage}. Skip further questions.` : userMessage;

    setIsAssistantFailed((prev) => ({
      ...prev,
      [post.id]: false,
    }));
    setLocalMessages((prev) => ({
      ...prev,
      [post.id]: [
        ...prev[post.id],
        { type: "user", message: userMessage },
      ],
    }));

    setMessageInputs((prev) => ({
      ...prev,
      [post.id]: "",
    }));
    setIsAssistantWaiting((prev) => ({
      ...prev,
      [post.id]: true,
    }));
    elementRef.current?.scrollIntoView();

    try {
      const payload = { 
        message: currentMessage,
        organization_id: organizationUuid,
        owner_email: ownerUserEmail
      };
      const data = await sendMessage(payload);
      queryClient.invalidateQueries(["organization", organizationUuid]);
      queryClient.setQueryData(["post-messages", postSkuuid], data);

      const lastMessage = data[data.length - 1];
      if (lastMessage.message_type === "json") {
        queryClient.setQueryData([`post-${postSkuuid}`], (old) => {
          return {
            ...old,
            caption: lastMessage.message.caption,
            image_prompt: lastMessage.message.image_prompt,
          };
        });
      }

      elementRef.current?.scrollIntoView();
      setSkipQuestions(false);
      setLocalMessages((prev) => ({
        ...prev,
        [post.id]: [],
      }));
      setIsAssistantWaiting((prev) => ({
      ...prev,
      [post.id]: false,
    }));
    }catch(error){
      setIsAssistantWaiting((prev) => ({
      ...prev,
      [post.id]: false,
    }));
      setIsAssistantFailed((prev) => ({
        ...prev,
        [post.id]: true,
      }));
      elementRef.current?.scrollIntoView();
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed fetch assistance message: ${errorMessage}`
      );
    }
  }

  const sendMessageWithGroqAI = () => {
    interactWithAiAssistant(messageInputs[post.id] || "", (payload) =>
      sendMessageWithGroq(postPkuuid, postSkuuid, payload, organizationUuid)
    );
  };


  const sendMessageWithOpenAI = (message) => {
    interactWithAiAssistant(message || messageInputs[post.id] || "", async (payload) => {
      const currentPayload = {
        ...payload,
        knowledge_base_id: knowledgeBaseId,
      };
      const { run_id } = await sendMessage(
        postPkuuid,
        postSkuuid,
        currentPayload,
        organizationUuid
      );
      return pollForCompletion(run_id);
    });
  };

  const pollForCompletion = async (runId) => {
    try {
      const payload = {
        run_id: runId,
        organization_id: organizationUuid,
        owner_email: ownerUserEmail,
      };
      const response = await fetchMessageByRunId(
        postPkuuid,
        postSkuuid,
        payload
      );

      return response;
    } catch (error) {
      throw error;
    }
  };

  const aIModelMessageHandlerMap = {
    [AIModelType.OPEN_AI]: sendMessageWithOpenAI,
    [AIModelType.GROQ_AI]: sendMessageWithGroqAI,
  };

  return (
    <>
      {messagesQuery.data.length <= 0 && (
        <PromptTemplatesDisplayer
          promptTemplates={promptTemplates}
          handlePromptChange={handlePromptChange}
        />
      )}
      <ul
        className={`flex flex-col overflow-y-auto rounded-xl p-4 ${
          messagesQuery.data.length <= 0
            ? "h-[calc(80vh-250px)] max-lg:h-[calc(80vh-175px)]"
            : "h-[calc(100vh-250px)] max-lg:h-[calc(80vh-175px)]"
        }`}
      >
        {localMessages[post.id]?.map((m, i) => (
          <div key={i}>
            <li
              className={
                m.type === "user"
                  ? "flex flex-row mt-3"
                  : "flex flex-row mt-3 rounded-xl bg-blue-50"
              }
            >
              <span className="p-2">
                {m.type === "user" ? (
                  <div className="w-8 h-8 p-2.5 bg-main-blue rounded-full flex items-center justify-center">
                    <div className="text-center text-white text-base font-normal">
                      {auth.user.given_name[0]}
                      {auth.user.family_name[0]}
                    </div>
                  </div>
                ) : (
                  <AgentAvatar className="bg-black rounded-full w-8 h-8 px-1 py-1 shadow-md" />
                )}
              </span>
              <span className="p-2 pt-2 inline-flex items-center whitespace-pre-wrap">
                {typeof m.message === "object"
                  ? m.message.assistant_message
                  : m.message}
              </span>
            </li>
            {m.reference && <SourcesList reference={m.reference} />}
          </div>
        ))}
        {isAssistantWaiting[post.id] && (
          <li className="flex flex-row mt-3 rounded-xl bg-very-light-blue">
            <span className="py-4 pl-3">
              <Typing />
            </span>
          </li>
        )}
        {isAssistantFailed[post.id] && (
          <li className="flex flex-row mt-3 rounded-xl bg-very-light-blue">
            <span className="p-2">
              <AgentAvatar className="bg-black rounded-3xl w-8 h-8 px-1 py-1 shadow-transparent" />
            </span>
            <span className="p-2">
              <span className="pr-2">It seems like something went wrong.</span>
              <Button onClick={() => sendMessageWithOpenAI("retry")}>
                Retry
              </Button>
            </span>
          </li>
        )}
        <li ref={elementRef}></li>
      </ul>
      <AssistantMessageInput
        key={post.id}
        messageInput={messageInputs[post.id] || ""}
        handleChange={handleInputChange}
        handleKeyDown={handleKeyDown}
        disabled={isAssistantWaiting[post.id] || !orgHasCredits}
        handleSendClick={handleSendClick}
        skipQuestions={skipQuestions}
        setSkipQuestions={setSkipQuestions}
        organization={organization}
        errorMessage={!orgHasCredits ? "To start creating a post add balance to your account." : null}
      />
    </>
  );
}
