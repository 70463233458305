
import { Navigate } from "react-router-dom";

import LocalStorageKeys from "../data/enums/localStorageKeys";
import useLocalStorage from "../hooks/LocalStorage/useLocalStorage";
import { extractPkSk } from "../utils/common";

export default function LoadOrganizationPage() {
    const [orgSkuuid] = useLocalStorage(
        LocalStorageKeys.ORGANIZATION_ID_KEY,
        null
    );

    const { skuuid } = extractPkSk(orgSkuuid);

    return (
        <Navigate
            to={`/mkt/organizations/${skuuid}/projects`}
            replace
        />
    );

}
