import React, { useEffect, Suspense } from "react";
import { Amplify } from "aws-amplify";
import { Routes, Route } from "react-router-dom";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { ErrorBoundary } from "react-error-boundary";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import ProjectsPage from "./components/Projects/ProjectsPage";

import { AuthProvider, RequireAuth, AuthCallback } from "./AuthProvider";
import LandingPageV2 from "./components/Landing/LandingPageV2";
import LoginPage from "./LoginPage";
import PrivacyPolicyPage from "./pages/privacy-policy";
import Console from "./Console";
import Settings from "./components/Settings";
import LinkedInCallback from "./components/LinkedInCallback";
import Loading from "./components/common/Loading";
import Campaigns from "./components/Campaigns";
import KnowledgeBase from "./components/knowledge_base/KnowledgeBase";
import XCallback from "./components/XCallback";
import InstagramCallback from "./components/InstagramCallback";
import FacebookCallback from "./components/FacebookCallback";

import EditKnowledgeBase from "./components/knowledge_base/EditKnowledgeBase";
import PromptTemplate from "./components/prompt_template/PromptTemplate";
import GeneralOrgSettings from "./components/organizations/GeneralSettings";
import CreateKnowledgeBase from "./components/knowledge_base/CreateKnowledgeBase";
import * as NotificationService from "./utils/notificationService";
import PostEditStep from "./components/designStudio/PostEditStep";
import ResourceLayout from "./layouts/ResourceLayout";
import SettingLayout from "./layouts/SettingLayout";
import LoadOrganizationPage from "./pages/LoadOrganization";

import {
  COGNITO_DOMAIN,
  COGNITO_USER_POOL_APP_CLIENT_ID,
  COGNITO_USER_POOL_ID,
} from "./config/constants";

import "./App.css";
import CampaignProvider from "./context/campaigns/CampaignProvider";
import RequireRole from "./RequireRole";
import ForbiddenScreen from "./components/common/pages/Forbidden";
import PromptTempateProvider from "./context/PromptTemplate/PromptTempateProvider";
import UserManagement from "./components/organizations/UserManagement";
import RoleType from "./data/enums/roleType";
import ProductDisplay from "./components/payments/Product";
import ConfirmCheckoutPage from "./pages/ConfirmCheckout";
import ErrorFallback from "./components/common/pages/ErrorFallback";
import SignInMethodType from "./data/enums/signInMethodType";
import CampaignPage from "./pages/CampaignPage";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolClientId: COGNITO_USER_POOL_APP_CLIENT_ID,

      loginWith: {
        oauth: {
          domain: COGNITO_DOMAIN,
          // TODO: revisit aws.cognito.signin.user.admin scope
          scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
          redirectSignIn: [
            "http://localhost:8080/auth/callback",
            "https://campaignsplanet.com/auth/callback",
            "https://www.campaignsplanet.com/auth/callback",
            "https://stg.campaignsplanet.com/auth/callback",
          ],
          redirectSignOut: [
            "http://localhost:8080/",
            "https://campaignsplanet.com/",
            "https://www.campaignsplanet.com/",
            "https://stg.campaignsplanet.com/",
          ],
          responseType: "token",
        },
      },

      cookieStorage: {
        domain: "localhost",
        path: "/",
        expires: 1,
        sameSite: "strict",
        secure: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN !== "localhost",
      },
    },
  },
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

function App() {
  useEffect(() => {
    var noHashURL = window.location.href.replace(/#.*$/, "");
    window.history.replaceState("", document.title, noHashURL);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<Loading />}>
            {NotificationService.renderToastContainer()}
            <CampaignProvider>
              <Routes>
                <Route path="/" element={<LandingPageV2 />} />
                <Route path="/login" element={<LoginPage signInMethodType={SignInMethodType.GOOGLE}/>} />
                <Route path="/login-user" element={<LoginPage signInMethodType={SignInMethodType.EMAIL_PASSWORD}/>} />
                <Route path="/privacy" element={<PrivacyPolicyPage />} />
                <Route path="/auth/callback" element={<AuthCallback />} />

                <Route path="/forbidden" element={<ForbiddenScreen />} />
                <Route
                  path="/mkt"
                  element={
                    <RequireAuth>
                      <Console />
                    </RequireAuth>
                  }
                >
                  <Route
                    path="load-organization"
                    element={<LoadOrganizationPage />}
                  />
                  <Route path="apps/linkedin/auth" element={<LinkedInCallback />} />
                  <Route path="apps/x/auth" element={<XCallback />} />
                  <Route path="apps/instagram/auth" element={<InstagramCallback />} />
                  <Route path="apps/facebook/auth" element={<FacebookCallback />} />
                  <Route path="organizations">
                    <Route path=":organizationskuuid">
                      <Route
                        path="after-checkout"
                        element={<ConfirmCheckoutPage />}
                      />
                      <Route path="resources" element={<ResourceLayout />}>
                        <Route
                          path="knowledge-bases"
                          element={<KnowledgeBase />}
                        />
                        <Route
                          path="knowledge-bases/create"
                          element={<CreateKnowledgeBase />}
                        />
                        <Route
                          path="knowledge-bases/edit"
                          element={<EditKnowledgeBase />}
                        />
                        <Route
                          path="prompt-templates"
                          element={
                            <PromptTempateProvider>
                              <PromptTemplate />
                            </PromptTempateProvider>
                          }
                        />
                        <Route path="target-platforms" element={<Settings />} />
                      </Route>
                      <Route
                        path="settings"
                        element={
                          <RequireRole
                            allowedRoles={[RoleType.ADMIN, RoleType.OWNER]}
                          >
                            <SettingLayout />
                          </RequireRole>
                        }
                      >
                        <Route
                          path="general-settings"
                          element={<GeneralOrgSettings />}
                        />
                        <Route
                          path="billing"
                          element={
                            // todo: create billing page
                            <RequireRole allowedRoles={[RoleType.OWNER]}>
                              <ProductDisplay />
                            </RequireRole>
                          }
                        />
                        <Route
                          path="user-management"
                          element={<UserManagement />}
                        />
                      </Route>
                      <Route path="projects">
                        <Route index element={<ProjectsPage />} />
                        <Route path=":projectskuuid/campaigns">
                          <Route index element={<Campaigns />} />
                          <Route
                            path=":campaignskuuid/assistant"
                            element={<CampaignPage />}
                          />
                          <Route
                            path=":campaignskuuid/posts/:postSkuuid/edit"
                            element={<PostEditStep />}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </CampaignProvider>
          </Suspense>
        </ErrorBoundary>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
