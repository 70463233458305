import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";


export const fetchLinkedinPages = async (organizationuuid, payload) => {
  const session = await fetchAuthSession();
  const response = await fetch(
    `${API_SERVER}/organization/${organizationuuid}/social-accounts/list-pages`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload),
    }
  );

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Network response was not ok");
  }

  return data;
};

export const createLinkedinAccount = async(organizationuuid, payload) => {
  const session = await fetchAuthSession();
  const response = await fetch(
    `${API_SERVER}/organization/${organizationuuid}/social-account`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload),
    }
  );

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Network response was not ok");
  }

  return data;
}
