import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";


import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  deleteKnowledgeBase,
  deleteOrganizationResourceFile,
  updateKnowledgeBase,
} from "../../services";
import * as NotificationService from "../../utils/notificationService";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import delete_data from "../../assets/delete_data_filter.svg";
import DeleteKnowledgeBaseConfirmationModal from "./DeleteKnowledgeBaseConfirmationModal";
import FileList from "../FileList/FileList";
import DragDropFile from "../DragDropFile/DragDropFile";
import { formatFileData, validateFileSizeNType } from "../../utils";
import { useCreateFile, useGetPresignedUrl, useUploadFile } from "../../hooks/File";
import { MAX_SIZE_PER_FILE, MAX_FILES } from "../../config/constants";


function KnowledgeBaseEditor() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { organizationskuuid } = useParams();
  const kb = location.state?.kb || {};
  const [files, setFiles] = useState([]);
  const [knowledgeBaseName, setKnowledgeBaseName] = useState("");
  const [, setKnowledgeBaseId] = useState(null);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [filesToAdd, setFilesToAdd] = useState([]);
  const [deletingId, setDeletingId] = useState(null);
  const createFile = useCreateFile(organizationskuuid);
  const createPresignedUrl = useGetPresignedUrl();
  const uploadFile = useUploadFile(); 
  const [deleteKBConfirmationModalIsOpen, setDeleteKBConfirmationModalIsOpen] =
    useState(false);

  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (newKnowledgeBase) => {
      const skuuid = kb.sk.split("#")[1];
      return await updateKnowledgeBase(
        organizationskuuid,
        skuuid,
        newKnowledgeBase
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["knowledgeBases", organizationskuuid],
      });
      NotificationService.notifySuccess("Knowledge base updated successfully.");
    },
    onError: (error) => {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to update Knowledge base: ${errorMessage}`
      );
    },
  });

  const removeFileMutation = useMutation({
    mutationFn: async (file) => {
      return await deleteOrganizationResourceFile(
        organizationskuuid,
        kb.sk.split("#")[1],
        file.sk.split("#")[1]
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["knowledgeBases", organizationskuuid],
      });
      NotificationService.notifySuccess("File removed successfully.");
    },
    onError: (error) => {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(`Failed to remove file: ${errorMessage}`);
    },
  });

  const processFileUpload = (validFiles) => {
    const filePayload = validFiles.map(async (file) => {
      const { presigned_url, file_s3_uri, file_id } =
        await createPresignedUrl.mutateAsync({
          file_name: file.file_name,
          organization_skuuid: organizationskuuid,
        });

      await uploadFile.mutateAsync({ file, presigned_url });

      return createFile.mutateAsync({
        id: file_id,
        content: file_s3_uri,
        file_name: file.file_name,
        file_size: file.file_size,
        status: "UPLOADING",
        origin_sk: kb.sk,
      });

    });

    return filePayload;
  };

  const handleValidFiles = (newFiles) => {
    const validFiles = validateFileSizeNType(newFiles, MAX_SIZE_PER_FILE);
    if (validFiles.length + files.length > MAX_FILES) {
      NotificationService.notifyError(
        `You can only upload a maximum of ${MAX_FILES} files.`
      );
      return [];
    }

    return validFiles;
  }

  const handleSave = async () => {
    updateKnowledgeBaseHandler();
    Promise.all(filesToRemove.map((file) => removeFileMutation.mutate(file)))
    const filePayload = processFileUpload(filesToAdd);
    await Promise.all(filePayload);

    navigate(
      `/mkt/organizations/${organizationskuuid}/resources/knowledge-bases`
    );
  };

  const handleCancelEdit = () => {
    navigate(
      `/mkt/organizations/${organizationskuuid}/resources/knowledge-bases`
    );
  };

  const updateKnowledgeBaseHandler = useCallback(async () => {
    const payload = {
      title: knowledgeBaseName,
    };
    mutation.mutate(payload);
  }, [knowledgeBaseName]);

  const handleRemoveFile = (file) => {
    setFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile.file_name !== file.file_name)
    );
    setFilesToRemove((prevFiles) => [...prevFiles, file]);
  };

  const handleFileDrop = (e) => {
    const newFiles = Array.from(e.dataTransfer.files);
    if (files.length === 0) return;
    const validFiles = handleValidFiles(newFiles);
    const formatFiles = formatFileData(validFiles);
    setFiles([...files, ...formatFiles]);
    setFilesToAdd([...filesToAdd, ...formatFiles]);
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    if (files.length === 0) return;
    const validFiles = handleValidFiles(newFiles);
    const formatFiles = formatFileData(validFiles);
    setFiles([...files, ...formatFiles]);
    setFilesToAdd([...filesToAdd, ...formatFiles]);
  }

  useEffect(() => {
    if (kb) {
      setKnowledgeBaseId(kb.id);
      setKnowledgeBaseName(kb.title);
      setFiles(kb.files)
    }
  }, []);

  const confirmDelete = async () => {
    try {
      const skuuid = deletingId.split("#")[1];
      await deleteKnowledgeBase(organizationskuuid, skuuid);
      NotificationService.notifySuccess("Knowledge base deleted successfully");
      setDeleteKBConfirmationModalIsOpen(false);
      navigate(
        `/mkt/organizations/${organizationskuuid}/resources/knowledge-bases`
      );
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to delete knowledge base: ${errorMessage}`
      );
    }
  };

  return (
    <div className="flex justify-center p-14">
      <div className="flex flex-col border border-gray-300 rounded-md w-[35%] bg-gray-100 p-4 gap-4">
        <div className="flex">
          <h1 className="text-xl text-center text-gray-600 flex-grow">
            Edit Knowledge Base
          </h1>
          <img
            src={delete_data}
            alt="Delete knowlede base"
            className="cursor-pointer ml-auto w-4 h-4"
            onClick={() => {
              setDeletingId(kb.id);
              setDeleteKBConfirmationModalIsOpen(true);
            }}
          />
        </div>
        <div>
          <label className="text-base text-gray-600">Knowledge Base Name</label>
          <div className="bg-white rounded-md border border-slate-300 focus:ring-1 focus:ring-blue-500 mt-1">
            <input
              type="text"
              className="w-full bg-transparent border border-gray-300 text-sm tracking-wide outline-none bg-white"
              placeholder="Enter Knowledge Base name"
              value={knowledgeBaseName}
              onChange={(e) => setKnowledgeBaseName(e.target.value)}
            />
          </div>
        </div>

        <label className="text-base text-gray-600">Uploads</label>
        <FileList
          files={files}
          isLoadingVisible={false}
          showDeleteOption={true}
          handleRemoveFile={handleRemoveFile}
          styles="bg-white rounded-lg p-2"
        />

        <DragDropFile
          onDrop={handleFileDrop}
          onChange={handleFileChange}
        />

        <ActionButtonsGroup
          onCancel={handleCancelEdit}
          onSave={handleSave}
          saveDisabled={false}
          bgColorClass="bg-gray-100"
        />
        <DeleteKnowledgeBaseConfirmationModal
          showModal={deleteKBConfirmationModalIsOpen}
          setOpenModal={setDeleteKBConfirmationModalIsOpen}
          confirmDelete={confirmDelete}
        />
      </div>
    </div>
  );
}

export default KnowledgeBaseEditor;
