import { createContext, useContext } from 'react'
import { useLocation, Navigate } from "react-router-dom"
import { useQuery } from '@tanstack/react-query'
import { signInWithRedirect, fetchUserAttributes, fetchAuthSession, signOut, signIn } from "aws-amplify/auth"
import {
  COGNITO_DOMAIN,
  COGNITO_USER_POOL_APP_CLIENT_ID,
} from "./config/constants";

import SyncOrganizationStorage from './utils/sync-organization-storage';

let AuthContext = createContext()

const checkSession  = async () => {
  const session = await fetchAuthSession()
  if (session.tokens)
    return await fetchUserAttributes()

  return Promise.resolve(null)
}

function AuthProvider({ children }) {
  const signInWithCredentials = async (username, password) => {
    try {
      const { isSignedIn } = await signIn({
        username,
        password,
        options: {
          authFlowType: 'USER_AUTH',
        },
      });
      await reCheckSession();
      return {success: isSignedIn};
    } catch (error) {
      return {success: false, reason: error.message};
    }
  }

  const signin = async () => {
    await signInWithRedirect({
      provider: "Google",
    })
  }

  const signout = async () => {
    const userIdentities = user.identities ? JSON.parse(user.identities): [];
    const isGoogleProvider = userIdentities.some(identity => identity.providerType === "Google");
    await signOut();
    if (!isGoogleProvider) {
      const originLocation = `${window.location.origin}/`;
      const logoutUrl = `https://${COGNITO_DOMAIN}/logout` +
      `?client_id=${COGNITO_USER_POOL_APP_CLIENT_ID}`+
      `&logout_uri=${encodeURIComponent(originLocation)}`;
      window.location.href = logoutUrl;
    }
  }

  const { data: user, refetch: reCheckSession } = useQuery({
    queryKey: ['user'],
    queryFn: checkSession,
  })

  let value = { user, signin, signout, signInWithCredentials }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

function RequireAuth({ children }) {
  let location = useLocation()
  const auth = useContext(AuthContext)

  localStorage.setItem('callback-redirect', location.pathname)
  return auth.user ?
    <>
      {children}
    </>
    :
    <Navigate to="/login" state={{ from: location }} replace />
}

function AuthCallback() {
  const locationPath = localStorage.getItem('callback-redirect')


  SyncOrganizationStorage();

  if (locationPath) {
    return <Navigate to={locationPath} />
  } else {
    return <Navigate to='/mkt/organizations' />
  }
}

export { AuthProvider, AuthContext, RequireAuth, AuthCallback }
