import { FaPlus } from "react-icons/fa";

function FileInput({ onChange, fileInputRef, onClick, isMultiple }) {
  return (
    <label
      htmlFor="fileInput"
      className="flex cursor-pointer justify-end items-center text-main-blue font-bold gap-2"
    >
      <input
        id="fileInput"
        type="file"
        ref={fileInputRef}
        multiple={isMultiple}
        onChange={onChange}
        accept=".pdf,.docx,.doc,.txt,.csv,.xlsx"
        className="hidden"
      />
      <button
        type="button"
        onClick={onClick}
        className="flex items-center gap-2 py-2 text-main-blue font-bold hover:text-blue-700 transition active:text-blue-400"
      >
        <FaPlus className="w-4 h-4" />
        Add Content
      </button>
    </label>
  );
}

export default FileInput;
