import { fetchAuthSession } from "aws-amplify/auth";
import { Navigate, useLocation } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Loading from "./common/Loading";
import { API_SERVER } from "../config/constants";
import { callbackURL, extractPkSk } from "../utils/common";
import * as NotificationService from "../utils/notificationService";
import LocalStorageKeys from "../data/enums/localStorageKeys";
import useLocalStorage from "../hooks/LocalStorage/useLocalStorage";
import { formatErrorMesage } from "../utils/formaters";

function useURLQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function XCallback() {
  const queryClient = useQueryClient();
  let urlQueryParams = useURLQueryParams();
  const [isLoading, setIsLoading] = useState(false);
  const [organizationId] = useLocalStorage(LocalStorageKeys.ORGANIZATION_ID_KEY, null);
  const { skuuid: organizationskuuid } = extractPkSk(organizationId);

  useEffect(() => {
    setIsLoading(true);
    fetchAuthSession().then((session) => {
      let metadata = urlQueryParams.get("state");
      let error = urlQueryParams.get("error");
      let parsedMetadata = {};

      try {
        parsedMetadata = JSON.parse(atob(metadata));
      } catch (error) {
        console.error("Failed to decode metadata", error);
      }

      if (error) {
        error = formatErrorMesage(error)
        NotificationService.notifyError("Failed to connect: " + error);
        setIsLoading(false);
        return;
      }

      const accountName = parsedMetadata.account_name || 'Default account name';

      fetch(API_SERVER + `/organization/${organizationskuuid}/social-accounts/x/auth`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({
          "code": urlQueryParams.get("code"),
          "callback_url": callbackURL("x"),
          "meta": {
            "account_name": accountName,
          },
          "organization_id": organizationskuuid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.message || "Network response was not ok");
          }
          NotificationService.notifySuccess("Social network connected successfully");
          queryClient.invalidateQueries(["user-social-accounts", organizationskuuid]);
        })
        .catch((error) => {
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(`Failed to connect: ${errorMessage}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, [urlQueryParams]);

  return isLoading ? <Loading /> : <Navigate to={`/mkt/organizations/${organizationskuuid}/resources/target-platforms`}/>;
}

export default XCallback;