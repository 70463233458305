import React, { Suspense, useReducer, useState } from "react";
import { Outlet, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  FaDonate,
  FaFileAlt,
  FaAtlas,
  FaUsersCog,
  FaWhatsappSquare,
  FaWhmcs,
} from "react-icons/fa";

import { useQuery } from "@tanstack/react-query";

import { ReactComponent as Logo } from "./assets/logos/campaigns-planet-logo.svg";
import { ReactComponent as ProjectsIcon } from "./assets/icons/projects_icon.svg";
import { ReactComponent as BookIcon } from "./assets/knowledge_base.svg";
import { ReactComponent as SettingsIcon } from "./assets/settings.svg";
import RoleType from "./data/enums/roleType";
import { READABLE_SITE_NAME } from "./config/constants";
import Loading from "./components/common/Loading";
import ProjectsList from "./components/leftMenu/ProjectsList";
import UserProfile from "./components/sidebar/UserProfile";
import LocalStorageKeys from "./data/enums/localStorageKeys";
import useLocalStorage from "./hooks/LocalStorage/useLocalStorage";
import { getMyRole } from "./services";
import { extractPkSk, navigationMenuReducer } from "./utils";
import UserMenu from "./layouts/UserMenu";
import ItemList from "./components/leftMenu/ItemList";

function Console() {
  const navigate = useNavigate();
  const [activeProjectId, setActiveProjectId] = useState(null);
  const [orgId, setOrgId] = useLocalStorage(
    LocalStorageKeys.ORGANIZATION_ID_KEY,
    null
  );
  const { organizationskuuid: paramsOrgSkuuid } = useParams();
  const [section, dispatch] = useReducer(navigationMenuReducer, {
    projects: true,
    resources: false,
    settings: false,
  });
  const { skuuid: storageOrgSkuuid } = extractPkSk(orgId);
  const orgSkuuid = paramsOrgSkuuid || storageOrgSkuuid;
  const activeNavLink = `text-main-blue bg-indigo-50 rounded-lg`;
  const SIZE = 18;
  const { data: myOrgInfo } = useQuery({
    queryKey: ["MyRole", orgSkuuid],
    queryFn: () => getMyRole(orgSkuuid),
    staleTime: 1 * 60 * 1000,
    refetchInterval: (data) => {
      if (data.error?.message === "UnauthorizedError") return false;
      return 30 * 1000;
    },
    enabled: !!orgSkuuid,
    retry: false,
    throwOnError: true,
    useErrorBoundary: true,
  });

  const sideBarNavLinkStyles = ({ isActive }) => {
    return isActive ? activeNavLink : "";
  };

  const handleOnClickLink = (action, label) => {
    if (label === "Projects") setActiveProjectId(null);

    dispatch({ type: action });
  };

  const items = [
    {
      id: 1,
      label: "Projects",
      url: `/mkt/organizations/${orgSkuuid}/projects`,
      icon: <ProjectsIcon />,
      active: section.projects,
      action: "show_project_items",
      allowedRoles: [RoleType.OWNER, RoleType.ADMIN, RoleType.MEMBER],
    },
    {
      id: 2,
      label: "Resources",
      url: `/mkt/organizations/${orgSkuuid}/resources/knowledge-bases`,
      icon: <BookIcon />,
      active: section.resources,
      action: "show_resource_items",
      allowedRoles: [RoleType.OWNER, RoleType.ADMIN, RoleType.MEMBER],
      children: [
        {
          label: "Knowledge Bases",
          url: `/mkt/organizations/${orgSkuuid}/resources/knowledge-bases`,
          icon: <FaAtlas size={SIZE} />,
        },
        {
          label: "Prompt Templates",
          url: `/mkt/organizations/${orgSkuuid}/resources/prompt-templates`,
          icon: <FaFileAlt size={SIZE} />,
        },
        {
          label: "Target Platforms",
          url: `/mkt/organizations/${orgSkuuid}/resources/target-platforms`,
          icon: <FaWhatsappSquare size={SIZE} />,
        },
      ],
    },
    {
      id: 3,
      label: "Settings",
      url: `/mkt/organizations/${orgSkuuid}/settings/general-settings`,
      icon: <SettingsIcon />,
      active: section.settings,
      action: "show_setting_items",
      allowedRoles: [RoleType.OWNER, RoleType.ADMIN],
      children: [
        {
          label: "General Settigs",
          url: `/mkt/organizations/${orgSkuuid}/settings/general-settings`,
          icon: <FaWhmcs size={SIZE} />,
        },
        {
          label: "Billing",
          url: `/mkt/organizations/${orgSkuuid}/settings/billing`,
          icon: <FaDonate size={SIZE} />,
        },
        {
          label: "User Managment",
          url: `/mkt/organizations/${orgSkuuid}/settings/user-management`,
          icon: <FaUsersCog size={SIZE} />,
        },
      ],
    },
  ];

  return (
    <>
      <UserMenu orgId={orgId} />
      <div className="relative w-screen h-screen flex flex-row justify-start items-center font-['Roboto'] overflow-hidden">
        <div className="max-lg:hidden w-80 self-stretch px-3 pt-6 pb-2 bg-white shadow shadow-very-light-gray-gray flex-col items-center gap-8 inline-flex z-10">
          <div
            onClick={() => navigate("../")}
            className="self-stretch text-main-blue pl-3.5 justify-start items-center gap-3 inline-flex hover:cursor-pointer"
          >
            <Logo />
            <div className="text-black text-2xl font-semibold leading-tight">
              {READABLE_SITE_NAME}
            </div>
          </div>
          <div className="flex flex-col justify-between h-full">
            <ul>
              {items.map((item) => {
                if (!item.allowedRoles.includes(myOrgInfo?.role)) return null;

                return (
                  <React.Fragment key={`item-key-${item.id}`}>
                    <li>
                      <NavLink
                        key={`item-link-${item.label}`}
                        to={item.url}
                        onClick={() =>
                          handleOnClickLink(item.action, item.label)
                        }
                        className={`self-stretch h-12 px-4 justify-start items-center gap-6 inline-flex w-full ${
                          item.active ? activeNavLink : ""
                        } `}
                      >
                        {item.icon}
                        <div className="grow shrink basis-0 self-stretch py-3 flex-col justify-start items-start gap-0.5 inline-flex">
                          <div className="self-stretch text-base font-normal leading-normal tracking-wide">
                            {item.label}
                          </div>
                        </div>
                      </NavLink>
                    </li>

                    {item.active && item.label === "Projects" ? (
                      <div
                        className={`overflow-hidden transition-all duration-300 ${
                          item.active ? "opacity-100" : "max-h-0 opacity-0"
                        }`}
                      >
                        <ProjectsList
                          activeProjectId={activeProjectId}
                          setActiveProjectId={setActiveProjectId}
                          organizationId={orgSkuuid}
                          styles={sideBarNavLinkStyles}
                        />
                      </div>
                    ) : (
                      <div
                        className={`overflow-hidden transition-all duration-300 ${
                          item.active
                            ? "max-h-40 opacity-100"
                            : "max-h-0 opacity-0"
                        }`}
                      >
                        <ItemList
                          items={item.children}
                          styles={sideBarNavLinkStyles}
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
            <UserProfile orgId={orgId} setOrgId={setOrgId} />
          </div>
        </div>
        <div className="bg-clip-padding bg-very-light-gray w-full h-full px-5 py-5 max-lg:p-0 overflow-x-hidden">
          <div className="bg-white rounded-md w-full h-full">
            <Suspense fallback={<Loading />}>
              <Outlet context={{ orgId, setActiveProjectId }} />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}

export default Console;
