import React, { useEffect, useRef, useState } from 'react';
import { RxLightningBolt } from "react-icons/rx";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { BiDetail } from "react-icons/bi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const options = [
    {
        label: "Quick",
        icon: <RxLightningBolt />,
        description: "Quick Mode skips additional prompts for faster interactions.",
        value: true
    },
    {
        label: "Detailed",
        icon: <BiDetail />,
        description: "Detailed Mode asks follow-up questions to improve the output.",
        value: false
    }
];

export default function SkipQuestionsSelector({
    skipQuestions, setSkipQuestions, disabled
}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const refElement = useRef(null);

    const handleClickOutside = (event) => {
        if (refElement.current && !refElement.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, []);

    return (
        <div className="absolute right-[20px] top-5" ref={refElement}>
            <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className={`flex items-center justify-center w-14 h-10 border rounded-lg ${disabled ? "bg-[#E8F2FF]": "bg-[#E8F2FF]"} ${disabled ? "cursor-not-allowed": "cursor-pointer"}`}
                aria-label="Toggle skip questions mode"
                disabled={disabled}
            >
                {skipQuestions 
                    ? <RxLightningBolt color="#1DA1F2" className="ml-1 color-[#1DA1F2]" size={15} />
                    : <BiDetail color="#1DA1F2" size={15} />
                }
                {dropdownOpen
                    ? <FaChevronUp color="#1DA1F2" className="ml-1" size={15} />
                    : <FaChevronDown color="#1DA1F2" className="ml-1" size={15} />
                }
            </button>
            {dropdownOpen && (
                <div className="absolute bottom-16 right-0 w-40 h-[80px] bg-white border rounded-lg">
                    {options.map(({ label, icon, description, value }) => (
                        <DropdownItem
                            key={label}
                            label={label}
                            icon={icon}
                            description={description}
                            isActive={skipQuestions === value}
                            onClick={() => { setSkipQuestions(value); setDropdownOpen(false); }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

const DropdownItem = ({ label, icon, description, isActive, onClick }) => (
    <button
        className={`group flex items-center justify-between w-full px-4 py-2 relative ${isActive ? "bg-main-blue text-white" : "hover:bg-gray-100"}`}
        onClick={onClick}
    >
        {icon}
        {label}
        <IoMdInformationCircleOutline className="cursor-pointer" />
        <div className="absolute left-[170px] bg-[#585858] text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity w-[200px]">
            {description}
        </div>
    </button>
);