import { useState, useEffect, useMemo } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"

import { fetchAuthSession } from "aws-amplify/auth"

import Loading from "./common/Loading"
import { API_SERVER } from "../config/constants"
import { callbackURL, extractPkSk } from "../utils/common"
import * as NotificationService from "../utils/notificationService";
import LocalStorageKeys from "../data/enums/localStorageKeys";
import useLocalStorage from "../hooks/LocalStorage/useLocalStorage";
import { formatErrorMesage } from "../utils/formaters";


function useURLQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function LinkedInCallback() {
  const navigate = useNavigate();
  const urlQueryParams = useURLQueryParams();
  const [organizationId] = useLocalStorage(LocalStorageKeys.ORGANIZATION_ID_KEY, null);
  const { skuuid: organizationskuuid } = extractPkSk(organizationId);
  const [queryParams, setQueryParams] = useState("");

  useEffect(() => {
    fetchAuthSession().then((session) => {
      let metadata = urlQueryParams.get("state");
      let error = urlQueryParams.get("error");
      let parsedMetadata = {};

      try {
        parsedMetadata = JSON.parse(atob(metadata));
      } catch (error) {
        console.error("Failed to decode metadata", error);
      }
      
      if (error) {
        error = formatErrorMesage(error)
        NotificationService.notifyError("Failed to connect: " + error);
        return;
      }

      const accountName = parsedMetadata.account_name || 'Default account name';

      fetch(`${API_SERVER}/organization/${organizationskuuid}/social-accounts/linkedin/auth`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${session.tokens.idToken.toString()}`
        },
        body: JSON.stringify({
          "code": urlQueryParams.get("code"),
          "callback_url": callbackURL("LinkedIn"),
          "meta": {
            "account_name": accountName
          },
          "organization_id": organizationskuuid,
        }),
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.message || "Network response was not ok");
        }

        setQueryParams(new URLSearchParams({
          access_token: data.access_token,
          social_account: "LINKEDIN",
          account_name: accountName,
        }));
        NotificationService.notifySuccess("You have been authenticated successfully");
      })
      .catch((error) => {
        const errorMessage = error.message || "An unexpected error occurred";
        NotificationService.notifyError(`Failed to connect: ${errorMessage}`);
        navigate(`/mkt/organizations/${organizationskuuid}/resources/target-platforms`);
      });
    })
  }, [urlQueryParams])


  return !queryParams ? (
    <Loading />
  ) : (
    <Navigate
    to={`/mkt/organizations/${organizationskuuid}/resources/target-platforms?${queryParams.toString()}`}
  />
  )
}

export default LinkedInCallback;
