import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";
import { delay } from "../utils/common";


export const sendMessage = async (campaignSkuuid, postSkuuid, payload, organizationskuuid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${organizationskuuid}/campaigns/${campaignSkuuid}/posts/${postSkuuid}/user/messages`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to send message:", error);
    throw error;
  }
};

export const fetchMessageByRunId = async (campaignSkuuid, postSkuuid, payload) => {
  try {
    const organizationskuuid = payload.organization_id;
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${organizationskuuid}/campaigns/${campaignSkuuid}/posts/${postSkuuid}/assistant/messages`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(payload),
      }
    );
    if (!response.ok) {
      const responseData = await response.json();
      throw new Error(responseData.message || "Network response was not ok");
    }

    if (response.status === 202) {
      await delay(1000);
      return fetchMessageByRunId(campaignSkuuid, postSkuuid, payload);
    }

    const responseData = await response.json();
    return responseData.messages;
  } catch (error) {
    console.error("Failed fetch assistance message: ", error);
    throw error;
  }
};

