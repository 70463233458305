import React from "react";
import { Modal } from "flowbite-react";

import ActionButtonsGroup from "../buttons/ActionButtonsGrup";

const BaseModal = ({
  headerMessage,
  children,
  showModal,
  isDismissible,
  size,
  handleClose,
  mainClassName,
  saveLabel,
  saveDisabled,
  handleSave,
  handleSaveDisabled,
  bodyClassName,
  cancelLabel,
  cancelDisabled,
  handleCancel,
  isThereModalFooter = true,
}) => {

  return (
    <Modal
      show={showModal}
      dismissible={isDismissible}
      size={size}
      onClose={handleClose}
      className={mainClassName}
    >
      <Modal.Header className={bodyClassName}>
        {headerMessage}
      </Modal.Header>
      <Modal.Body >
        {children}
      </Modal.Body>
      {isThereModalFooter && <Modal.Footer>
        <div className="w-full flex-row justify-end space-x-3">
          <ActionButtonsGroup
            saveLabel={saveLabel}
            saveDisabled={saveDisabled}
            onSave={handleSave}
            onCancel={!cancelLabel ? handleClose : handleCancel}
            onSaveDisabled={handleSaveDisabled}
            cancelLabel={cancelLabel}
            cancelDisabled={cancelDisabled}
          />
        </div>
      </Modal.Footer>}
    </Modal>
  );
};

export default BaseModal;
